import { render, staticRenderFns } from "./PanelSmartForm.vue?vue&type=template&id=0a19766a"
import script from "./PanelSmartForm.vue?vue&type=script&lang=js"
export * from "./PanelSmartForm.vue?vue&type=script&lang=js"
import style0 from "./PanelSmartForm.vue?vue&type=style&index=0&id=0a19766a&prod&lang=scss"
import style1 from "./PanelSmartForm.vue?vue&type=style&index=1&id=0a19766a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormRadio, BFormRadioGroup, BFormSelect, BDropdownItemButton, BDropdown, BFormSelectOption, BSpinner, BImgLazy, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormRadio, BFormRadioGroup, BFormSelect, BDropdownItemButton, BDropdown, BFormSelectOption, BSpinner, BImgLazy, BButton, BModal})
    

export default component.exports