<template>
	<div>
		<div>{{ item.value }}</div>
		<div class="small">
			{{ item.data.address.value }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: { type: Object, required: true },
	},
};
</script>
