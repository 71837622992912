<template>
	<div v-if="isBlockShown" id="empty-registration" class="panel form theme-light">
		<div class="container">
			<div class="panel_form">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
						<div
							v-if="
								fetchedData.status && block.block_title !== null
							"
						>
							<div
								class="title title-lg mb-4"
								v-html="block.block_title"
							/>
						</div>
						<div
							v-else-if="
								fetchedData.status && block.block_title === null
							"
						>
							<div class="title">
								{{ $t('registrationOn') }}
								{{
									'type_titles' in fetchedData
										? fetchedData.type_titles.title_dative
										: ''
								}}
							</div>
							<h2>
								{{ fetchedData.title }}
							</h2>
						</div>
						<div v-else-if="block.block_title_closed">
							<h2>
								{{ block.block_title_closed }}
							</h2>
						</div>
						<div v-else>
							<h2>
								{{ $t('registrationClosed') }}
							</h2>
						</div>

						<div v-if="block.description">
							<h4>
								{{ block.description }}
							</h4>
						</div>

						<div v-if="freeParkingLevels">
							<h6>
								Для участников программы лояльности Школы бесплатная парковка на 12 месяцев при пожертвовании (необходимо авторизоваться в Личном кабинете до внесения взноса):
								<ul>
									<li
										v-for="freeParkingLevel in freeParkingLevels"
										:style="freeParkingLevel.isCustomerLevel ? 'font-weight : bolder' : ''"
									>
										уровень участия {{ freeParkingLevel.name }} - от {{ freeParkingLevel.donation }} рублей
									</li>
								</ul>
							</h6>
						</div>

						<!-- временый блок для 4982 -->
						<div
							v-if="
								fetchedData.id === 4982 ||
									fetchedData.id === 8792
							"
							class=""
						>
							<p>
								To Register for the event, please fill in the
								application form below. The fields marked * have
								to be filled in.
							</p>
							<p>
								By registering, you give your consent that your
								personal data are processed by Moscow School of
								Management SKOLKOVO, an entity under the
								legislation of the Russian Federation, even if
								you are residing in the European Union (EU), and
								your rights and legal remedies are subject to
								the
								<a
									:href="`https://pd.rkn.gov.ru/authority/p146/p164/`"
									target="_blank"
								>Federal Law of the Russian Federation of 27
									July 2006 N 152-FZ on Personal Data</a>, different from EU legislation without
								additional safeguards provided. The European
								Delegation to the Russian Federation processes
								personal data pursuant to
								<a
									:href="`https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32018R1725`"
									target="_blank"
								>Regulation (EU) 2018/1725 of the European
									Parliament and of the Council of 23 October
									2018 on the protection of natural persons
									with regard to the processing of personal
									data by the Union institutions, bodies,
									offices and agencies and on the free
									movement of such data</a>.
							</p>
							<p>
								You can find more information on the processing
								of your data by the EU Delegation to the Russian
								Federation related to the
								<a
									:href="`https://energy.skolkovo.ru/event/senec/energy-transition-2020/EEAS_Privacy_Statement_EU-RUSSIA_CLIMATE_conference_March_2020.pdf`"
									target="_blank"
								>EU-RUSSIA Climate Conference in the Privacy
									Statement – Data Protection Notice of the
									Delegation</a>.
							</p>
						</div>
						<!-- END временый блок для 4982 -->

						<!-- <div v-if="!fetchedData.status" class="form-note">
							<p
								class="icon-exclam"
							>
								{{ $t('notificationsNote') }}
							</p>
						</div> -->

						<div
							v-else-if="
								fetchedData.status &&
									block.scenario === SCENARIOS.DONATION &&
									!block.is_goods_donation &&
									!block.is_registration_donation
							"
							class="form-note"
						>
							<p
								v-if="fetchedData.id === 7032"
								class="icon-exclam"
							>
								Друзья, в этом году мы решили продолжить
								традицию, начатую в 2019 году. Билет на Конвент
								2021 года – это ваш добровольный вклад в
								развитие Школы. Вы можете совершить любое
								пожертвование в грантовый фонд. Для получения
								билета на Конвент нужно заполнить форму
								регистрации, выбрать группу, указать сумму и
								совершить пожертвование.
							</p>
							<p v-else class="icon-exclam">
								{{ $t('supportNote') }}
							</p>
						</div>

						<div
							v-else-if="
								fetchedData.status &&
									block.scenario === SCENARIOS.DONATION &&
									block.is_registration_donation
							"
						>
							<div class="input-group input-group">
								<div class="d-flex">
									<b-form-checkbox
										v-model="registrationAndDonation"
										name="student-button"
									>
										Я хочу поддержать проект и получить
										global tool pack в подарок
									</b-form-checkbox>
								</div>
							</div>
						</div>

						<!-- <div
							v-if="fetchedData.id === 7032"
						>
							<p class="mb-0">
								Выберите турнир для участия в конкурсе на лучшую группу
							</p>
						</div> -->
					</div>
				</div>

				<div
					v-if="fetchedData.status"
					class="row justify-content-between"
				>
					<div class="col-12 col-sm-12 col-md-12 col-lg-8 mb-3">
						<form
							:id="'smart-form-' + block.id"
							data-role="validator"
							name="landingForm"
							@submit.prevent="submit"
						>
							<div
								v-if="
									block.sellable_programs.length !== 0 &&
										!block.select_and_hide_price_lists
								"
								class="project-choise"
							>
								<div
									v-if="block.singular_buy === false"
									:class="[
										block.is_goods_donation ? 'd-none' : '',
										'position-relative',
									]"
								>
									<button
										type="button"
										class="btn btn-sm btn-primary rounded-pill mb-2"
										@click="selectAllPrograms"
									>
										{{ $t('selectAll') }}
									</button>
									<b-form-group
										label
										:class="{
											'form-group--error':
												$v.selectedPrograms.$error,
										}"
									>
										<b-form-checkbox-group
											v-model="selectedPrograms"
											name="programs"
											stacked
											class="mb-4"
											@change="onProgramSelect"
										>
											<b-form-checkbox
												v-for="(
													item, index
												) in sellablePrograms"
												:id="`event-form-item-${item.value}`"
												:key="index"
												:value="item.value"
											>
												{{ item.text }}
											</b-form-checkbox>
										</b-form-checkbox-group>
										<label
											v-if="!$v.selectedPrograms.required"
											v-model.trim="
												$v.selectedPrograms.$model
											"
											class="error"
										>{{ $t('chooseOneProgram') }}</label>
									</b-form-group>
								</div>

								<div v-if="block.singular_buy === true">
									<b-form-group
										label
										:class="{
											'form-group--error':
												$v.selectedPrograms.$error,
										}"
									>
										<b-form-radio-group
											v-model="selectedProgramsSingular"
											name="programs"
											stacked
											@change="onProgramSelect"
										>
											<b-form-radio
												v-for="(
													item, index
												) in sellablePrograms"
												:id="`event-form-item-${item.value}`"
												:key="index"
												:value="item.value"
												:checked="query"
											>
												{{ item.text }}
											</b-form-radio>
										</b-form-radio-group>
										<label
											v-if="!$v.selectedPrograms.required"
											v-model.trim="
												$v.selectedPrograms.$model
											"
											class="error"
										>{{ $t('chooseOneProgram') }}</label>
									</b-form-group>
								</div>
							</div>

							<div
								v-if="
									block.scenario === SCENARIOS.DONATION &&
										!block.is_goods_donation &&
										registrationAndDonation &&
										fetchedData.id === 13706
								"
								class="btn-panel mt-4"
							>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{ active: amountButtonStates[500] }"
									@click="setAmount(500)"
								>
									500 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[1000],
									}"
									@click="setAmount(1000)"
								>
									1 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[2000],
									}"
									@click="setAmount(2000)"
								>
									2 000 &#8381;
								</button>
							</div>

							<div
								v-if="
									block.scenario === SCENARIOS.DONATION &&
										!block.is_goods_donation &&
										registrationAndDonation &&
										fetchedData.id === 3826
								"
								class="btn-panel mt-4"
							>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{ active: amountButtonStates[500] }"
									@click="setAmount(500)"
								>
									500 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[1000],
									}"
									@click="setAmount(1000)"
								>
									1 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[2000],
									}"
									@click="setAmount(2000)"
								>
									2 000 &#8381;
								</button>
							</div>

							<div
								v-else-if="
									block.scenario === SCENARIOS.DONATION &&
										!block.is_goods_donation &&
										registrationAndDonation &&
										fetchedData.id === 10266
								"
								class="btn-panel mt-4"
							>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{ active: amountButtonStates[500] }"
									@click="setAmount(500)"
								>
									500 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[1000],
									}"
									@click="setAmount(1000)"
								>
									1 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[3000],
									}"
									@click="setAmount(3000)"
								>
									3 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[5000],
									}"
									@click="setAmount(5000)"
								>
									5 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[10000],
									}"
									@click="setAmount(10000)"
								>
									10 000 &#8381;
								</button>
							</div>

							<div
								v-else-if="
									(fetchedData.id === 16365 || fetchedData.id === 16975)
										&& block.scenario === SCENARIOS.DONATION"
								class="btn-panel mt-4"
							>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[1000],
									}"
									@click="setAmount(1000)"
								>
									1 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[3000],
									}"
									@click="setAmount(3000)"
								>
									3 000 &#8381;
								</button>
							</div>

							<div
								v-else-if="
									block.scenario === SCENARIOS.DONATION &&
										!block.is_goods_donation &&
										registrationAndDonation &&
										fetchedData.id
								"
								class="btn-panel mt-4"
							>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[100000],
									}"
									@click="setAmount(100000)"
								>
									100 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[50000],
									}"
									@click="setAmount(50000)"
								>
									50 000 &#8381;
								</button>
								<button
									type="button"
									class="btn btn-outline-primary"
									:class="{
										active: amountButtonStates[10000],
									}"
									@click="setAmount(10000)"
								>
									10 000 &#8381;
								</button>
							</div>

							<div
								v-if="
									(block.scenario === SCENARIOS.BUY_PROGRAM ||
										block.scenario === SCENARIOS.DONATION) &&
										registrationAndDonation
								"
								class="form-row"
							>
								<div
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.amount.$error,
									}"
								>
									<!-- <div style="font-size: 1rem">
										<span v-if="!amount && block.scenario !== SCENARIOS.DONATION">{{ $t('totalAmount') }}</span>
                    <span class="form-control required" v-if="amount && block.scenario !== SCENARIOS.DONATION">{{ amount }} {{ getCurrency }}</span>
                    <input
                      v-if="block.scenario === SCENARIOS.DONATION"
                      :id="'amount_' + block.id"
                      v-model="amount"
                      data-msg="Пожалуйста, укажите сумму"
                      name="amount"
                      :placeholder="block.scenario === SCENARIOS.DONATION ? $t('otherSum') : $t('totalAmount')"
                      type="text"
                      class="form-control rounded-0 required"
                      :disabled="block.scenario === SCENARIOS.BUY_PROGRAM"
                      @input="onAmountInput($event)"
                    >
									</div> -->
									<input
										:id="'amount_' + block.id"
										v-model="amount"
										data-msg="Пожалуйста, укажите сумму"
										name="amount"
										:placeholder="
											block.scenario === SCENARIOS.DONATION
												? $t('otherSum')
												: $t('totalAmount')
										"
										type="text"
										class="form-control rounded-0 required"
										:disabled="block.scenario === SCENARIOS.BUY_PROGRAM"
										@input="onAmountInput($event)"
									>
									<label
										v-if="!$v.amount.required"
										v-model.trim="$v.amount.$model"
										class="error"
										:for="'amount_' + block.id"
									>{{ $t('specifySum') }}</label>
									<label
										v-if="!$v.amount.between"
										v-model.trim="$v.amount.$model"
										class="error"
										:for="'amount_' + block.id"
									>{{ $t('specifySumFromTo') }}</label>
								</div>
							</div>

							<div class="form-row">
								<div
									v-if="block.fields.first_name.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.firstName.$error,
									}"
								>
									<input
										:id="'first_name_' + block.id"
										v-model.trim="$v.firstName.$model"
										:data-msg="$t('fillNameMessage')"
										name="first_name"
										:placeholder="`${$t('namePlaceholder')}${fetchedData.id === 16015 ? '  名字 ': ''}*`"
										type="text"
										class="form-control rounded-0 required"
									>
									<label
										v-if="!$v.firstName.required"
										v-model.trim="$v.firstName.$model"
										class="error"
										:for="'first_name_' + block.id"
									>{{ $t('fillNameMessage') }}</label>
								</div>
								<div
									v-if="block.fields.last_name.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.lastName.$error,
									}"
								>
									<input
										:id="'last_name_' + block.id"
										v-model.trim="lastName"
										:data-msg="$t('fillLastNameMessage')"
										name="last_name"
										:placeholder="`${$t('lastNamePlaceholder')}${fetchedData.id === 16015 ? ' 姓 ': ''}*`"
										type="text"
										class="form-control rounded-0 required"
									>
									<label
										v-if="!$v.lastName.required"
										v-model.trim="$v.lastName.$model"
										class="error"
										:for="'last_name_' + block.id"
									>{{ $t('fillLastNameMessage') }}</label>
								</div>

								<div
									v-if="block.fields.second_name.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.secondName.$error,
									}"
								>
									<input
										:id="'second_name_' + block.id"
										v-model.trim="secondName"
										:data-msg="$t('fillSecondNameMessage')"
										name="second_name"
										:placeholder="
											$t('secondNamePlaceholder')
										"
										type="text"
										class="form-control rounded-0 required"
									>
									<label
										v-if="!$v.secondName.required"
										v-model.trim="$v.secondName.$model"
										class="error"
										:for="'second_name_' + block.id"
									>{{
										$t('fillSecondNameMessage')
									}}</label>
								</div>

								<div
									v-if="block.fields.school_company.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.companyName.$error,
									}"
								>
									<input
										:id="'company_name_' + block.id"
										v-model.trim="companyName"
										:data-msg="$t('fillSchoolNameMessage')"
										name="company_name"
										:placeholder="
											block.fields.school_company.required
												? $t('companyPlaceholder') +
													' / ' +
													$t('SchoolName') +
													'*'
												: $t('SchoolName')
										"
										type="text"
										class="form-control rounded-0 required"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.school_company.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.companyName.required"
										v-model.trim="$v.companyName.$model"
										class="error"
										:for="'company_name_' + block.id"
									>{{
										$t('fillSchoolNameMessage')
									}}</label>
								</div>

								<div
									v-if="block.fields.company_name.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.companyName.$error,
									}"
								>
									<input
										:id="'company_name_' + block.id"
										v-model.trim="companyName"
										:data-msg="$t('fillCompanyNameMessage')"
										name="company_name"
										:placeholder="`${$t('companyPlaceholder')}${fetchedData.id === 16015 ? ' 公司名称 ': ''}${block.fields.company_name.required? '*': '' }`"
										type="text"
										class="form-control rounded-0 required"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.company_name.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.companyName.required"
										v-model.trim="$v.companyName.$model"
										class="error"
										:for="'company_name_' + block.id"
									>{{
										$t('fillCompanyNameMessage')
									}}</label>
								</div>

								<div
									v-if="block.fields.class_position.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.jobTitle.$error,
									}"
								>
									<input
										:id="'job_title_' + block.id"
										v-model.trim="jobTitle"
										:data-msg="$t('fillClassPlaceholder')"
										name="job_title"
										:placeholder="
											block.fields.class_position.required
												? $t('jobPlaceholder') +
													' / ' +
													$t('classPlaceholder') +
													'*'
												: $t('classPlaceholder')
										"
										type="text"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.class_position.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.jobTitle.required"
										v-model.trim="$v.jobTitle.$model"
										class="error"
										:for="'job_title_' + block.id"
									>{{ $t('fillClassPlaceholder') }}</label>
								</div>

								<div
									v-if="block.fields.job_title.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.jobTitle.$error,
									}"
								>
									<input
										:id="'job_title_' + block.id"
										v-model.trim="jobTitle"
										:data-msg="$t('fillJobMessage')"
										name="job_title"
										:placeholder="`${$t('jobPlaceholder')}${fetchedData.id === 16015 ? ' 职位 ': ''}${block.fields.job_title.required? '*': '' }`"
										type="text"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.job_title.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.jobTitle.required"
										v-model.trim="$v.jobTitle.$model"
										class="error"
										:for="'job_title_' + block.id"
									>{{ $t('fillJobMessage') }}</label>
								</div>

								<!-- Дополнительные поля -->
								<div
									v-if="block.fields.section.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.section.$error,
									}"
								>
									<select
										v-model.trim="section"
										:class="[
											'form-control',
											'rounded-0',
											'university-crutched',
											block.fields.section.required
												? 'required'
												: '',
										]"
										data-msg="Пожалуйста, укажите ваш профиль"
									>
										<option value="">
											Профиль{{
												block.fields.section.required
													? '*'
													: ''
											}}
										</option>
										<option
											value="Торговые сети и магазины"
										>
											Торговые сети и магазины
										</option>
										<option
											value="Производители и поставщики товаров"
										>
											Производители и поставщики товаров
										</option>
										<option
											value="Поставщики услуг и решений в сети"
										>
											Поставщики услуг и решений в сети
										</option>
									</select>
									<label
										v-if="!$v.section.required"
										v-model.trim="$v.section.$model"
										class="error"
										:for="'section_' + block.id"
									>Пожалуйста, укажите ваш профиль</label>
								</div>

								<div
									v-if="block.fields.legal.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.legal.$error,
									}"
								>
									<select
										v-model.trim="legal"
										:class="[
											'form-control',
											'rounded-0',
											'university-crutched',
											block.fields.legal.required
												? 'required'
												: '',
										]"
										data-msg="Пожалуйста, укажите юридическое лицо"
									>
										<option value="">
											Юридическое лицо{{
												block.fields.legal.required
													? '*'
													: ''
											}}
										</option>
										<option value="Да">
											Да
										</option>
										<option value="Нет">
											Нет
										</option>
									</select>
									<label
										v-if="!$v.legal.required"
										v-model.trim="$v.legal.$model"
										class="error"
										:for="'legal_' + block.id"
									>Пожалуйста, укажите юридическое
										лицо</label>
								</div>

								<div
									v-if="
										block.fields.planned_participation
											.enabled
									"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.planned_participation.$error,
									}"
								>
									<select
										v-model.trim="planned_participation"
										:class="[
											'form-control',
											'rounded-0',
											'university-crutched',
											block.fields.planned_participation
												.required
												? 'required'
												: '',
										]"
										data-msg="Пожалуйста, выберите значение"
									>
										<option value="">
											{{
												block.fields
													.planned_participation
													.required
													? 'Планируемое участие*'
													: 'Планируемое участие'
											}}
										</option>
										<option value="Он-лайн">
											Онлайн
										</option>
										<option value="Очно">
											Очно
										</option>
									</select>
									<label
										v-if="
											!$v.planned_participation.required
										"
										v-model.trim="
											$v.planned_participation.$model
										"
										class="error"
										:for="
											'planned_participation_' + block.id
										"
									>Пожалуйста, выберите значение</label>
								</div>

								<div
									v-if="block.fields.english_level.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.english_level.$error,
									}"
								>
									<b-form-select
										v-model="english_level"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.english_level.required
												? 'required'
												: '',
										]"
										data-msg="Пожалуйста, выберите значение"
										:options="languageLevel"
									/>
									<label
										v-if="!$v.english_level.required"
										v-model.trim="$v.english_level.$model"
										class="error"
										:for="'english_level_' + block.id"
									>{{ $t('englishLevelErr') }}</label>
								</div>

								<div
									v-if="
										block.fields.number_participants.enabled
									"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.number_participants.$error,
									}"
								>
									<input
										:id="'number_participants_' + block.id"
										v-model.trim="number_participants"
										data-msg="Пожалуйста, укажите значение"
										name="number_participants"
										:placeholder="
											block.fields.number_participants
												.required
												? 'Количество участников*'
												: 'Количество участников'
										"
										type="text"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.number_participants
												.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.number_participants.required"
										v-model.trim="
											$v.number_participants.$model
										"
										class="error"
										:for="'number_participants_' + block.id"
									>Пожалуйста, укажите значение</label>
								</div>

								<div
									v-if="block.fields.phone.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											showPhoneValidationError,
									}"
								>
									<VuePhoneNumberInput
										:id="'phone_' + block.id"
										ref="phoneInput"
										v-model="mobilePhone"
										name="phone"
										color="#000000"
										default-country-code="RU"
										:border-radius="0"
										:no-country-selector="false"
										:no-flags="true"
										:no-example="true"
										:show-code-on-list="true"
										:translations="{
											countrySelectorLabel: '',
											countrySelectorError: $t(
												'countrySelectorError'
											),
											phoneNumberLabel: `${$t('phoneNumberLabel')}${fetchedData.id === 16015 ? ' 手机号 ': ''}*`,
											example: $t('exampleEvent'),
										}"
										@update="phoneUpdate"
									/>
									<label
										v-if="showPhoneValidationError"
										class="error"
										:for="'phone_' + block.id"
									>{{ $t('fillPhoneMessage') }}</label>
								</div>
								<div
									v-if="block.fields.email.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.email.$error,
									}"
								>
									<input
										:id="'email_' + block.id"
										v-model="email"
										:data-msg="$t('fillEmailMessage')"
										name="email"
										type="email"
										:placeholder="`Email${fetchedData.id === 16015 ? ' 邮箱 ': ''}*`"
										class="form-control rounded-0 required"
									>
									<label
										v-if="!$v.email.required"
										v-model.trim="$v.email.$model"
										class="error"
										:for="'email_' + block.id"
									>{{ $t('fillEmailMessage') }}</label>
									<label
										v-if="!$v.email.email"
										v-model.trim="$v.email.$model"
										class="error"
										:for="'email_' + block.id"
									>{{ $t('validEmailMessage') }}</label>
								</div>
								<div
									v-if="
										block.fields.participation_format &&
											block.fields.participation_format
												.enabled
									"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.participation_format.$error,
									}"
								>
									<select
										v-model.trim="participation_format"
										:placeholder="$t('participationFormat')"
										:class="[
											'form-control',
											'rounded-0',
											'university-crutched',
											block.fields.participation_format
												.required
												? 'required'
												: '',
										]"
										data-msg="Пожалуйста, укажите формат участия"
									>
										<option value="">
											{{ $t('participationFormat') }}
											{{
												block.fields
													.participation_format
													.required
													? '*'
													: ''
											}}
										</option>
										<option value="Online">
											Онлайн
										</option>
										<option value="Offline">
											Офлайн
										</option>
									</select>
									<label
										v-if="!$v.participation_format.required"
										v-model.trim="
											$v.participation_format.$model
										"
										class="error"
										:for="
											'participation_format_' + block.id
										"
									>Пожалуйста, укажите формат
										участия</label>
								</div>
							</div>
							<div class="form-row">
								<div
									v-if="block.fields.city.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.city.$error,
									}"
								>
									<DaDataCity
										v-model="fullCityInfo"
										name="city"
										:initial-value="city"
										:input-options="{placeholder: $t('cityPlaceholder') + `${block.fields.city.required ? '*' : ''}` }"
										:style-classes="'required dadata-form'"
									/>
									<label
										v-if="!$v.city.required"
										v-model.trim="$v.city.$model"
										class="error"
										:for="'city_' + block.id"
									>{{ $t('fillCityMessage') }}</label>
								</div>

								<div
									v-if="block.fields.university.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error':
											$v.university.$error,
									}"
								>
									<input
										:id="'university_' + block.id"
										v-model.trim="university"
										:data-msg="$t('fillUniversityMessage')"
										name="university"
										:placeholder="
											block.fields.university.required
												? $t('universityPlaceholder') +
													'*'
												: $t('universityPlaceholder')
										"
										type="text"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.university.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.university.required"
										v-model.trim="$v.university.$model"
										class="error"
										:for="'university_' + block.id"
									>{{
										$t('fillUniversityMessage')
									}}</label>
								</div>
								<div
									v-if="block.fields.faculty.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': $v.faculty.$error,
									}"
								>
									<input
										:id="'faculty_' + block.id"
										v-model.trim="faculty"
										data-msg="Пожалуйста, укажите ваш факультет"
										name="job_title"
										:placeholder="
											block.fields.faculty.required
												? 'Факультет*'
												: 'Факультет'
										"
										type="text"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.faculty.required
												? 'required'
												: '',
										]"
									>
									<label
										v-if="!$v.faculty.required"
										v-model.trim="$v.faculty.$model"
										class="error"
										:for="'faculty_' + block.id"
									>{{ $t('universityFaculty') }}</label>
								</div>

								<div
									v-if="block.fields.age.enabled"
									class="form-group col-12 col-md-6 col-lg-6"
									:class="{
										'form-group--error': ageFilledErr,
									}"
								>
									<the-mask
										:id="'age_' + block.id"
										v-model="age"
										:mask="maskDate"
										:masked="true"
										:class="[
											'form-control',
											'rounded-0',
											block.fields.age.required
												? [
													'required',
													{
														'form-group--error':
															ageFilledErr ===
															true,
													},
												]
												: '',
										]"
										:data-msg="$t('fillBirthdayMessage')"
										name="age"
										:placeholder="
											block.fields.age.required
												? $t('birthdayPlaceholder') +
													'*'
												: $t('birthdayPlaceholder')
										"
										@change.native="changeAge"
									/>
									<label
										v-if="ageFilledErr"
										class="error"
										:for="'age_' + block.id"
									>{{ $t('fillBirthdayMessage') }}</label>
								</div>

								<div
									v-if="block.fields.student.enabled"
									class="form-group col-12 col-md-6 col-lg-6 mt-2"
								>
									<div class="input-group input-group">
										<div class="d-flex">
											{{ $t('areYouStudent') }}
											{{ $t('no') }}&nbsp;
											<b-form-checkbox
												v-model="student"
												name="student-button"
												switch
											>
												{{ $t('yes') }}
											</b-form-checkbox>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="form-row">
								<div class="form-group col-12 col-md-6 col-lg-6">
									<div class="input-group input-group">
										<template>
											<div class="d-flex">
											Пол: М&nbsp;
											<b-form-checkbox name="gander-button" switch>
												Ж
											</b-form-checkbox>
											</div>
										</template>
									</div>
								</div>
							</div>-->

							<div
								v-if="block.fields.comment.enabled"
								class="form-row mt-3"
								:class="{
									'form-group--error': $v.comment.$error,
								}"
							>
								<div
									class="form-group col-12 col-md-12 col-lg-12"
								>
									<div class="input-group">
										<textarea
											:id="'comments_' + block.id"
											v-model="comment"
											name="comments"
											type="text"
											:placeholder="
												block.additional_data_placeholder !==
													null
													? block.additional_data_placeholder
													: $t('commentPlaceholder')
											"
											:class="[
												'form-control',
												'rounded-0',
												'w-100',
												block.fields.comment.required
													? 'required'
													: '',
											]"
										/>
										<label
											v-model.trim="$v.comment.$model"
											class="error"
											:for="'comments_' + block.id"
										>
											<span v-if="!$v.comment.required">{{
												$t('fillCommentMessage')
											}}</span>
											<span v-if="!$v.comment.length"><br>{{
												$t('commentMaxLength')
											}}</span>
										</label>
									</div>
								</div>
							</div>

							<div
								v-if="
									(block.scenario === SCENARIOS.BUY_PROGRAM ||
										block.scenario === SCENARIOS.DONATION) &&
										registrationAndDonation
								"
								class="form-row payment_block align-items-end"
								:class="{
									'form-group--error':
										$v.paymentProvider.$error,
								}"
							>
								<div class="form-group col-12 col-md-6">
									<p class="mb-1">
										Способ оплаты:
									</p>
									<b-dropdown
										:id="
											'payment_type_dropdown_' + block.id
										"
										block
										variant="secondary-outer"
										menu-class="w-100"
										@click.native="
											setPaymentMethodDropdownText(
												$event.target
											)
										"
									>
										<template
											#button-content
											class="rounded-0"
										>
											{{ paymentMethodText }}
										</template>

										<!--										<b-dropdown-item-button-->
										<!--											type="button"-->
										<!--											name="action"-->
										<!--											class-->
										<!--											:class="{-->
										<!--												active:-->
										<!--													paymentProvider ===-->
										<!--													'qr' &&-->
										<!--													paymentMethod === 'qr',-->
										<!--											}"-->
										<!--											@click="-->
										<!--												selectPaymentProvider('qr');-->
										<!--												selectPaymentMethod('qr');-->
										<!--											"-->
										<!--										>-->
										<!--											<span class="icon icon-sberbank">Оплата по QR коду</span>-->
										<!--										</b-dropdown-item-button>-->

										<b-dropdown-item-button
											type="button"
											name="action"
											class
											:class="{
												active:
													paymentProvider ===
													'yandex' &&
													paymentMethod === 'sbp',
											}"
											@click="
												selectPaymentProvider('yandex'),
												selectPaymentMethod('sbp')
											"
										>
											<span class="icon icon-sbp">Система быстрых платежей</span>
										</b-dropdown-item-button>

										<b-dropdown-item-button
											type="button"
											name="action"
											class
											:class="{
												active:
													paymentProvider ===
													'yandex' &&
													paymentMethod ===
													'bank_card',
											}"
											@click="
												selectPaymentProvider('yandex'),
												selectPaymentMethod(
													'bank_card'
												)
											"
										>
											<span class="icon icon-credit_card">Банковская карта</span>
										</b-dropdown-item-button>
										<b-dropdown-item-button
											v-if="
												fetchedData.id !== 10826 &&
													block.scenario !== SCENARIOS.DONATION &&
													block.is_credit_disabled ===
													false
											"
											type="button"
											name="action"
											:class="{
												active:
													paymentProvider ===
													'tinkoff_loan_applications' &&
													paymentMethod === null,
											}"
											@click="
												selectPaymentProvider(
													'tinkoff_loan_applications'
												),
												selectPaymentMethod(null)
											"
										>
											<span class="icon icon-tinkoff">Заявка на кредит</span>
										</b-dropdown-item-button>
									</b-dropdown>
									<label
										v-if="!$v.paymentProvider.required"
										v-model.trim="$v.paymentProvider.$model"
										class="error"
										:for="
											'payment_type_dropdown_' + block.id
										"
									>Пожалуйста, выберите способ
										оплаты</label>
									<b-form-select
										v-if="
											paymentProvider ===
												'tinkoff_loan_applications' &&
												block.is_credit_disabled === false
										"
										v-model="loanPromoCode"
										:options="loanPromoCodes"
										name="loan_promo_code"
										class="mt-3 px-3 form-control rounded-0 loanPromoCode"
									>
										<template #first>
											<b-form-select-option
												:value="null"
												disabled
											>
												Период и ставка рассрочки
											</b-form-select-option>
										</template>
									</b-form-select>
								</div>
							</div>

							<div class="form-row">
								<!-- block.contract -->
								<div
									v-if="block.contract"
									class="form-group col-12 custom-control custom-checkbox d-block"
									:class="{
										'form-group--error': $v.contract.$error,
									}"
								>
									<div class="form-check">
										<input
											:id="'contract' + block.id"
											v-model="contract"
											data-msg="Необходимо принять условия"
											name="contract"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="contract-data"
											data-action="panels-smartform-contract-data-processing-and-public-offer-agreement"
										>

										<label
											class="custom-control-label form-check-label"
											:for="'contract' + block.id"
										>
											Отправляя форму, я
											<a
												:href="block.contract"
												target="_blank"
											>
												принимаю условия договора
												публичной оферты
											</a>
											*
										</label>
										<label
											v-if="!$v.contract.checked"
											v-model.trim="$v.contract.$model"
											class="error"
											:for="'contract' + block.id"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
								</div>
								<!-- block.contract -->

								<div
									class="form-group col-12 custom-control custom-checkbox d-block"
									:class="{
										'form-group--error':
											$v.agreements.personal_data.$error,
									}"
								>
									<!-- Временный блок для 7332 -->
									<div
										v-if="
											showPersonalData &&
												fetchedData.id === 7332
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-and-public-offer-agreement"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_rus.html`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											<span>
												и
												<a
													href="/pdf/SKOLKOVO_MPS.pdf"
													target="_blank"
												>
													принимаю условия договора
													публичной оферты
												</a>
											</span>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 7332 End -->

									<!-- Временный блок для 11816 -->
									<div
										v-else-if="
											showPersonalData &&
												fetchedData.id === 11816
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-and-public-offer-agreement"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_rus.html`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											<span>
												и
												<a
													href="/pdf/contract_legal_consulting_2022.pdf"
													target="_blank"
												>
													принимаю условия договора
													публичной оферты
												</a>
											</span>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 11816 End -->

									<!-- Временный блок для 7112 -->
									<div
										v-else-if="
											showPersonalData &&
												fetchedData.id === 7112
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-and-accept-grant-competition-terms"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_rus.html`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											<span>
												и принимаю условия
												<a
													href="/pdf/skolkovo_4_ord_100a21_030621.pdf"
													target="_blank"
												>
													положения о конкурсе грантов
												</a>
											</span>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 7112 End -->

									<!-- Временный блок для 5612 -->
									<div
										v-else-if="
											showPersonalData &&
												fetchedData.id === 5612
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-and-accept-grant-competition-terms"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю

											<a
												:href="`https://www.skolkovo.ru/static/personaldata_rus.html`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>

											<span>
												и принимаю условия
												<a
													href="/pdf/4-Ord-02_21_11.01.21_Practicum_Global_Shift.pdf"
													target="_blank"
												>
													положения о конкурсе грантов
												</a>
											</span>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 5612 End -->

									<!-- Временный блок для 9162 -->
									<div
										v-else-if="
											showPersonalData &&
												fetchedData.id === 9162
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-privacy-policy-agreement"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю своё согласие
											на обработку указанных мною
											персональных данных на условиях
											Политик конфиденциальности
											<a
												:href="`https://yandex.ru/legal/confidential/`"
												target="_blank"
											>
												ООО "ЯНДЕКС", АНО ДПО "ШАД"
											</a>
											и
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_rus.html`"
												target="_blank"
											>
												МШУ "СКОЛКОВО"
											</a>
											в целях обработки заявки и обратной
											связи по ней *
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 9162 End -->

									<!-- Временный блок для 5402 -->
									<div
										v-else-if="
											showPersonalData &&
												fetchedData.id === 5402
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-personal-data-processing-privacy-policy-agreement"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я принимаю условия договора публичной оферты для
											<a
												:href="`https://www.skolkovo.ru/pdf/contract-consulting.pdf`"
												target="_blank"
											>
												физических
											</a>
											и
											<a
												:href="`https://www.skolkovo.ru/pdf/contract-legal-consulting.pdf`"
												target="_blank"
											>
												юридических лиц
											</a>
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 5402 End -->

									<!-- Временный блок для 10136 -->
									<div
										v-else-if="
											fetchedData.id === 10136
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 10136 End -->

									<!-- Временный блок для 9556 -->
									<div
										v-else-if="
											fetchedData.id === 9556
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 9556 End -->

									<!-- Временный блок для 18435 -->
									<div
										v-else-if="
											fetchedData.id === 18435
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 18435 End -->

									<!-- Временный блок для 13786 -->
									<div
										v-else-if="
											fetchedData.id === 13786
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 13786 End -->

									<!-- Временный блок для 4085 -->
									<div
										v-else-if="
											fetchedData.id === 4085
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 4085 End -->

									<!-- Временный блок для 13466 -->
									<div
										v-else-if="
											fetchedData.id === 13466
										"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											data-action="panels-smartform-consent_ALUMNI"
										>

										<label
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											Отправляя форму, я даю
											<a
												:href="`https://www.skolkovo.ru/pdf/Consent_ADMISSION_WEB.pdf`"
												target="_blank"
											>
												согласие на обработку моих
												персональных данных
											</a>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>
									<!-- Временный блок для 13466 End -->

									<div
										v-else-if="showPersonalData"
										class="form-check"
									>
										<input
											:id="
												'condition_checkbox_' + block.id
											"
											v-model="agreements.personal_data"
											data-msg="Необходимо принять условия"
											name="terms"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
											:data-action="dataActionCondition"
										>
										<label
											v-if="
												block.scenario === SCENARIOS.REGISTRATION ||
													block.scenario === SCENARIOS.LEADSEND
											"
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											{{ $t('sendingFormIgive') }}
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_${
													$i18n.locale === 'ru'
														? 'rus'
														: 'eng'
												}.html`"
												target="_blank"
											>{{ $t('agreement') }}</a>

											<span
												v-if="fetchedData.id === 4382"
											>
												{{ $t('and') }}
												<a
													href="/pdf/skolkovo-practicum-general-terms-and-сonditions.pdf"
													target="_blank"
												>{{
													$t(
														'acceptTheTermsOfThePublicOfferAgreement',
													)
												}}</a>
											</span>
											*
										</label>

										<label
											v-else
											class="custom-control-label form-check-label"
											:for="
												'condition_checkbox_' + block.id
											"
										>
											{{ $t('submitAgreement') }}
											<a
												:href="`https://www.skolkovo.ru/static/personaldata_${
													$i18n.locale === 'ru'
														? 'rus'
														: 'eng'
												}.html`"
												target="_blank"
											>
												{{
													$t('personalDataProcessing')
												}}
											</a>

											<span
												v-if="block.entity_id === 1406"
											>
												{{ $t('and') }}
												<a
													href="/donation/donation_mps.pdf"
													target="_blank"
												>
													{{ $t('donationOffer') }}
												</a>
											</span>

											<span
												v-else-if="
													fetchedData.id === 10826
												"
											>
												{{ $t('and') }}
												<a
													href="/donation/donation_offer.pdf"
													target="_blank"
												>
													{{ $t('donationOffer') }}
												</a>
											</span>

											<span
												v-else-if="
													block.is_donation_scenario &&
														fetchedData.id === 7032
												"
											>
												{{ $t('and') }}
												<a
													href="/pdf/contract-convent.pdf"
													target="_blank"
												>{{
													$t('donationOffer')
												}}</a>
											</span>

											<span
												v-else-if="
													block.is_donation_scenario
												"
											>
												{{ $t('and') }}
												<a
													href="/donation/donation_offer.pdf"
													target="_blank"
												>
													{{ $t('donationOffer') }}
												</a>
											</span>

											<span
												v-else-if="
													block.scenario === SCENARIOS.BUY_PROGRAM &&
														fetchedData.id !== 1056
												"
											>
												{{ $t('and') }}
												{{ $t('acceptTerms') }}
												<a
													href="/pdf/contract.pdf"
													target="_blank"
												>
													{{ $t('personGen') }}</a>
												{{ $t('and') }}
												<a
													href="/pdf/contract_legal.pdf"
													target="_blank"
												>{{
													$t('corporateEntities')
												}}</a>
											</span>

											<span
												v-else-if="
													block.scenario === SCENARIOS.DONATION &&
														registrationAndDonation
												"
											>
												{{ $t('and') }}
												<a
													href="/donation/donation_offer.pdf"
													target="_blank"
												>
													{{ $t('donationOffer') }}
												</a>
											</span>
											*
										</label>
										<label
											v-if="
												!$v.agreements.personal_data
													.checked
											"
											v-model.trim="
												$v.agreements.personal_data
													.$model
											"
											class="error"
											:for="
												'condition_checkbox_' + block.id
											"
										>{{
											$t('agreementRequired')
										}}</label>
									</div>

									<!-- Временный блок для 18675 -->
									<div v-if="showMarketingEmails && fetchedData.id === 18675" />
									<!-- Временный блок для 18675 End -->

									<div v-else-if="showMarketingEmails" class="form-check">
										<input
											:id="'marketing_checkbox_' + block.id"
											v-model="agreements.marketing_emails"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
										>
										<label
											class="custom-control-label form-check-label"
											:for="'marketing_checkbox_' + block.id"
										>
											{{ $t('isAgreesToMarketingEmails') }}
										</label>
									</div>

									<div
										v-if="block.scenario === SCENARIOS.DONATION"
										class="form-check"
									>
										<input
											:id="'checkbox_' + block.id"
											v-model="donationName"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
										>
										<label
											class="custom-control-label form-check-label"
											:for="'checkbox_' + block.id"
										>
											{{ $t('donationsText') }}
											<a
												:href="`/donation/nas-podderzhali/`"
												target="_blank"
											>«{{ $t('weThank') }}»</a>
											{{ $t('patronsList') }}
										</label>
									</div>
									<div
										v-if="fetchedData.id === 10826"
										class="form-check"
									>
										<input
											:id="'checkbox_' + block.id"
											v-model="donationName"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
										>
										<label
											class="custom-control-label form-check-label"
											:for="'checkbox_' + block.id"
										>
											Я хочу, чтобы мое пожертвование было
											анонимным
										</label>
									</div>

									<!-- Временный блок для 18675 -->
									<div
										v-if="
											(!$auth.user ||
												!(
													$auth.user &&
													$auth.user.loyalty_id
												)) &&
												isShowLoyaltyCheckbox && fetchedData.id === 18675
										"
									/>
									<!-- Временный блок для 18675 End -->

									<!-- Временный блок для 4085 -->
									<div
										v-if="
											(!$auth.user ||
												!(
													$auth.user &&
													$auth.user.loyalty_id
												)) &&
												isShowLoyaltyCheckbox && fetchedData.id === 4085
										"
									/>
									<!-- Временный блок для 4085 End -->

									<div
										v-else-if="
											(!$auth.user ||
												!(
													$auth.user &&
													$auth.user.loyalty_id
												)) &&
												isShowLoyaltyCheckbox
										"
										class="form-check"
									>
										<input
											:id="'checkbox_loyalty' + block.id"
											v-model="isLoyal"
											type="checkbox"
											class="rounded-0 required form-check-input custom-control-input"
											value="personal-data"
										>
										<label
											class="custom-control-label form-check-label"
											:for="'checkbox_loyalty' + block.id"
										>
											Отправляя форму, я соглашаюсь стать
											участником Программы лояльности
											Школы и принимаю
											<a
												href="https://sk.skolkovo.ru/storage/loyality_legal.pdf"
											>правила участия</a>.
										</label>
									</div>

									<div
										v-if="showFreeParking"
										class="input-group"
									>
										<div class="d-flex col-12 p-0">
											<b-form-checkbox
												v-model="free_parking"
												name="free_parking"
												:disabled="! freeParkingEnabled"
												class="col-6"
											>
												Получить бесплатную парковку на Кампусе на 1 год
											</b-form-checkbox>
											<div
												v-if="freeParkingEnabled"
												class="form-group col-12 col-md-6 col-lg-6"
												:class="{
													'form-group--error': $v.car_number.$error,
												}"
											>
												<input
													:id="'car_number'"
													v-model="car_number"
													data-msg="Пожалуйста, укажите сумму"
													name="car_number"
													placeholder="Номер авто"
													type="text"
													class="form-control rounded-0 required"
												>
												<label
													v-if="!$v.car_number.required"
													v-model.trim="$v.car_number.$model"
													class="error"
													:for="'car_number'"
												>Введите номер авто</label>
											</div>
										</div>
										<div v-if="free_parking && freeParkingEnabled" class="mb-3">
											<div class="d-flex">
												<b-form-group label="Пропуск на Кампус">
													<b-form-radio-group
														v-model="have_free_parking_pass"
														plain
														:options="[
															{value: true, text: 'Есть'},
															{value: false, text: 'Нет'}
														]"
														name="have_free_parking_pass"
														required
													/>
												</b-form-group>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row submit_block">
								<div class="col-12 col-md-6">
									<p
										v-if="
											$v.selectedPrograms.$each.$error ||
												$v.selectedPrograms.$error
										"
										class="text-danger"
									>
										{{ $t('fillProgramsMessage') }}
									</p>
									<button
										v-if="
											block.scenario === SCENARIOS.DONATION &&
												!submittedStatus
										"
										:id="'submit' + block.id"
										type="submit"
										name="action"
										class="btn btn-lg btn-success mb-4"
									>
										<span v-if="fetchedData.id === 5302">{{
											$t('send')
										}}</span>
										<span v-else>{{
											$t('supportEvent')
										}}</span>
									</button>
									<button
										v-if="
											block.scenario === SCENARIOS.DONATION &&
												submittedStatus
										"
										:id="'submit' + block.id"
										type="submit"
										name="action"
										disabled
										class="btn btn-lg btn-success mb-4 disabled"
									>
										<b-spinner
											style="
												margin-right: 15px;
												width: 1.3em;
												height: 1.3em;
											"
										/>
										{{ $t('sending') }}
									</button>

									<button
										v-if="
											block.scenario !== SCENARIOS.DONATION &&
												!submittedStatus
										"
										:id="'submit' + block.id"
										type="submit"
										name="action"
										class="btn btn-lg btn-primary mb-4"
									>
										{{ $t('send') }}
									</button>
									<button
										v-if="
											block.scenario !== SCENARIOS.DONATION &&
												submittedStatus
										"
										:id="'submit' + block.id"
										type="submit"
										name="action"
										disabled
										class="btn btn-lg btn-primary mb-4 disabled"
									>
										<b-spinner
											style="
												margin-right: 15px;
												width: 1.3em;
												height: 1.3em;
											"
										/>
										{{ $t('sending') }}
									</button>
									<div
										v-if="isErrorCookie"
										class="cookie-error"
									>
										Необходимо принять Cookies
									</div>
								</div>
								<div
									v-if="$i18n.locale === 'ru'"
									class="col-12 col-md-6"
								>
									<p
										v-if="
											block.scenario === SCENARIOS.DONATION &&
												registrationAndDonation
										"
										class="payment-info"
									>
										Информируем вас, что согласно ст. 219
										Налогового кодекса РФ часть средств,
										направленных физическим лицом на
										благотворительные нужды, может быть
										возмещена по итогам года в форме
										налогового вычета.
									</p>
								</div>
							</div>
						</form>
					</div>
					<div class="col-12 col-lg-4 column_info">
						<LoyaltyFormBlock
							v-if="block.is_show_loyalty_block && selectedPrograms.length"
							:products="block.sellable_programs"
							:fetched-data="fetchedData"
							:selected-products="selectedPrograms"
						/>
						<div class="contacts_brief">
							<div class="row align-items-end">
								<div class="col-12 col-sm-6 col-lg-12">
									<div class="info_image">
										<b-img-lazy
											src="/donation/form_frame.svg"
											alt
										/>
									</div>
								</div>
								<div class="col-12 col-sm-6 col-lg-12">
									<div class="title">
										{{ block.right_column_title }}
									</div>
									<a
										:href="'mailto:' + block.email"
										class="title"
									>{{ block.email }}</a>
									<a
										:href="'tel:' + block.phone"
										class="title d-block"
									>{{ block.phone }}</a>
								</div>
							</div>
							<div class="row">
								<div
									v-for="(item, index) in block.items"
									:key="index"
									class="col-12 col-md-6 col-lg-12"
								>
									<hr>
									<div class="title mb-2">
										{{ item.contact_section }}
									</div>
									<div
										v-for="(
											sub_item, index
										) in item.sub_items"
										:key="index"
									>
										<p class="description mb-2">
											{{ sub_item.name }}
										</p>
										<p class="description">
											<a
												:href="'tel:' + sub_item.phone"
											>{{ sub_item.phone }}</a>
											<br>
											<a
												:href="
													'mailto:' + sub_item.email
												"
											>{{ sub_item.email }}</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="!fetchedData.status" class="row">
					<div class="col-12 col-sm-6 order-1 order-sm-0">
						<div class="btn-panel text-center text-sm-left">
							<a
								id="checkout_jivosite_top"
								onclick="jivo_api.open(); return false;"
								href="javascript:void(0)"
								class="btn btn-outline-primary"
								role="button"
							>
								Получить консультацию
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="lead_ok" size="lg">
			<div class="d-block">
				<div
					v-if="block.thanks_custom_page"
					v-html="block.thanks_custom_page"
				/>
				<div v-else>
					<p>
						Спасибо за ваше обращение. Информация будет передана
						нашим сотрудникам, и они свяжутся с вами в ближайшее
						время
					</p>
				</div>
			</div>
			<template #modal-footer="{ ok }">
				<b-button
					size="sm"
					class="rounded-pill"
					variant="primary"
					@click="ok()"
				>
					Закрыть
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	required, maxLength, email, between,
} from 'vuelidate/lib/validators';
import VuePhoneNumberInput from 'vue-phone-number-input';
// import VueDadata from 'vue-dadata';
import DaDataInput from '@/components/DaDataInput.vue';
import DaDataCity from '~/components/daData/DadataCity.vue';
import DadataCompany from '~/components/daData/DadataCompany.vue';
import { TheMask } from 'vue-the-mask';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import moment from '@/utils/moment';
import checkMobile from '~/mixins/checkMobile';
import { EVENT_FORM_SCENARIOS, SCENARIOS } from '~/utils/dictionaries';
import userProfile from '~/mixins/userProfile';
import agreementsMixin from '~/mixins/agreementsMixin';
import getTargetAction from '~/utils/getTargetAction';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';
import LoyaltyFormBlock from '~/components/loyalty/LoyaltyFormBlock.vue';

export default {
	name: 'PanelSmartForm',
	components: {
		LoyaltyFormBlock,
		TheMask,
		// VueDadata,
		DaDataInput,
		DaDataCity,
		DadataCompany,
		VuePhoneNumberInput,
	},
	mixins: [checkMobile, validationMixin, userProfile, agreementsMixin, checkIsBlockShown],
	props: ['block', 'fetchedData'],
	// eslint-disable-next-line complexity
	validations() {
		const validators = {
			firstName: {
				required,
				maxLength: maxLength(150),
			},
			lastName: {
				required,
				maxLength: maxLength(150),
			},
			email: {
				required,
				email,
			},
			car_number: {
				required: requiredIf(
					() => this.free_parking && this.car_number.length === 0,
				),
			},
			agreements: this.agreements_validation,
			amount: {
				between: between(1, 600000),
				required: requiredIf(
					() => this.block.scenario === SCENARIOS.DONATION,
				),
			},
			selectedPrograms: {
				required: requiredIf(
					() => this.block.scenario === SCENARIOS.DONATION
						|| this.block.scenario === SCENARIOS.BUY_PROGRAM
						|| (this.block.scenario === SCENARIOS.REGISTRATION && this.block.sellable_programs.length > 0),
				),
				$each: {
					required,
				},
			},

		};

		if (this.block.contract) {
			validators.contract = {
				checked: (value) => value === true,
			};
		}

		if (
			this.fetchedData.id === 4982
			|| this.fetchedData.id === 5772
			|| this.fetchedData.id === 8792
			|| this.fetchedData.id === 8782
		) {
			validators.video = {
				checked: (value) => value === true,
			};
		}

		if (
			(this.block.scenario === SCENARIOS.BUY_PROGRAM
				|| this.block.scenario === SCENARIOS.DONATION)
			&& this.registrationAndDonation
		) {
			validators.paymentProvider = {
				required,
			};
		}
		if (this.block.fields.company_name.enabled) {
			validators.companyName = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.company_name.required) {
				validators.companyName.required = required;
			}
		}

		if (this.block.fields.school_company.enabled) {
			validators.companyName = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.school_company.required) {
				validators.companyName.required = required;
			}
		}

		if (this.block.fields.second_name.enabled) {
			validators.secondName = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.second_name.required) {
				validators.secondName.required = required;
			}
		}

		if (this.block.fields.job_title.enabled) {
			validators.jobTitle = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.job_title.required) {
				validators.jobTitle.required = required;
			}
		}

		if (this.block.fields.class_position.enabled) {
			validators.jobTitle = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.class_position.required) {
				validators.jobTitle.required = required;
			}
		}

		if (this.block.fields.faculty.enabled) {
			validators.faculty = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.faculty.required) {
				validators.faculty.required = required;
			}
		}

		if (this.block.fields.section.enabled) {
			validators.section = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.section.required) {
				validators.section.required = required;
			}
		}

		if (this.block.fields.legal.enabled) {
			validators.legal = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.legal.required) {
				validators.legal.required = required;
			}
		}

		if (this.block.fields.planned_participation.enabled) {
			validators.planned_participation = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.planned_participation.required) {
				validators.planned_participation.required = required;
			}
		}

		if (this.block.fields.english_level.enabled) {
			validators.english_level = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.english_level.required) {
				validators.english_level.required = required;
			}
		}

		if (this.block.fields.number_participants.enabled) {
			validators.number_participants = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.number_participants.required) {
				validators.number_participants.required = required;
			}
		}

		if (
			this.block.fields.participation_format
			&& this.block.fields.participation_format.enabled
		) {
			validators.participation_format = {
				maxLength: maxLength(200),
			};

			validators.certificate_number = {};
			validators.pcr_test_number = {};

			if (this.block.fields.participation_format.required) {
				validators.participation_format.required = required;
			}
		}

		if (this.block.fields.age.enabled) {
			validators.age = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.age.required) {
				validators.age.required = required;
			}
		}

		if (this.block.fields.university.enabled) {
			validators.university = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.university.required) {
				validators.university.required = required;
			}
		}

		if (this.block.fields.comment.enabled) {
			validators.comment = {
				maxLength: maxLength(400),
			};

			if (this.block.fields.comment.required) {
				validators.comment.required = required;
			}
		}

		if (this.block.fields.city.enabled) {
			validators.city = {
				maxLength: maxLength(200),
			};

			if (this.block.fields.city.required) {
				validators.city.required = required;
			}
		}

		return validators;
	},
	data() {
		return {
			SCENARIOS,
			neededAmountForFreeParking: null,
			freeParkingLevels: null,
			// currency: null,
			// individual: null,
			isLoyal: false,
			free_parking: false,
			car_number: '',
			parentSlug: null,
			countryCode: null,
			selectedItem: '',
			dadataToken: process.env.dadataApiKey,
			fullCityInfo: '',
			firstName: '',
			lastName: '',
			secondName: '',
			email: '',
			mobilePhone: '',
			mobilePhoneInternational: '',
			amount: '',
			companyName: '',
			jobTitle: '',
			schoolCompany: '',
			classPosition: '',
			city: '',
			student: true,
			registrationAndDonation: true,
			university: '',
			faculty: '',
			age: '',
			section: '',
			legal: '',
			planned_participation: '',
			english_level: '',
			number_participants: '',
			comment: '',
			participation_format: '',
			certificate_number: '',
			pcr_test_number: '',
			landingId: this.fetchedData.landing_id,
			isMobilePhoneValid: false,
			showPhoneValidationError: false,
			terms: false,
			contract: false,
			donationName: false,
			video: false,
			timestamp: null,
			generalLandingId: '',
			paymentProvider: null,
			paymentMethod: null,
			paymentMethodText: 'Выберите способ оплаты',
			amountButtonStates: {
				10000: false,
				50000: false,
				200000: false,
				500000: false,
			},
			selectedPrograms: [],
			selectAllProgramsButtonChecked: false,
			registrationProgram: null, // Текстовый заголовок названия программы, используется при регистрации
			isPresentationNeeded: null, // Нужна ли презентация
			ageFilledErr: null,
			isShowLoginModal: true,
			submittedStatus: false,
			maskDate: '##.##.####',
			loanPromoCode: null,
			loanPromoCodes: [
				{ value: 'installment_0_0_3_5,19', text: '3 месяца' },
				{ value: 'installment_0_0_6_8,84', text: '6 месяцев' },
				{ value: 'installment_0_0_10_13,42', text: '10 месяцев' },
				{ value: 'installment_0_0_12_15,59', text: '12 месяцев' },
			],
			certificateFilledErr: null,
			pcrTestNumberFilledErr: null,
			select_landing_id: null,
			query: null,
			isErrorCookie: false,
			have_free_parking_pass: null,
			lost_free_parking_pass: false,
		};
	},
	computed: {
		...mapState('cookiesDisclaimer', ['isCookiesAccepted']),
		...mapGetters({
			isAlumni: 'profile/isAlumni',
		}),
		languageLevel() {
			const lang = [
				{
					value: '',
					textRu: 'Уровень английского*',
					textEn: 'English level',
					disabled: true,
					default: true,
				},
				{
					value: 'Advanced',
					textRu: 'Advanced',
					textEn: 'Advanced',
					disabled: false,
					default: false,
				},
				{
					value: 'Upper Intermediate',
					textRu: 'Upper Intermediate',
					textEn: 'Upper Intermediate',
					disabled: false,
					default: false,
				},
				{
					value: 'Intermediate',
					textRu: 'Intermediate',
					textEn: 'Intermediate',
					disabled: false,
					default: false,
				},
				{
					value: 'Elementary',
					textRu: 'Elementary',
					textEn: 'Elementary',
					disabled: false,
					default: false,
				},
				{
					value: 'Beginner',
					textRu: 'Beginner',
					textEn: 'Beginner',
					disabled: false,
					default: false,
				},
			];
			return lang.map((i) => ({
				value: i.value,
				text: this.$i18n.locale === 'ru' ? i.textRu : i.textEn,
				disabled: i.disabled,
				default: i.default,
			}));
		},
		sellablePrograms() {
			const programs = [];

			this.block.sellable_programs.forEach((program) => {
				const programData = {};
				programData.text = program.display_title;
				programData.value = program.id;
				programData.landing_id = program.crm_landing_id;
				programs.push(programData);
			});

			return programs;
		},
		selectedProgramsSingular: {
			set(val) {
				this.selectedPrograms = [val];
			},
			get() {
				if (
					typeof this.selectedPrograms !== 'undefined'
					&& this.selectedPrograms.length === 1
				) {
					return this.selectedPrograms[0];
				}

				return null;
			},
		},
		ageValidate() {
			return (
				this.age?.length === this.maskDate.length
				&& moment(this.age, 'DD.MM.YYYY').isValid()
			);
		},
		admissionId() {
			return this.fetchedData?.admission?.id;
		},
		isShowLoyaltyCheckbox() {
			return this.block.sellable_programs.length === 0 || this.block.sellable_programs.some(
				(program) => program.is_earn_loyalty || program.is_charge_loyaty,
			);
		},
		showFreeParking() {
			return this.block.scenario === SCENARIOS.DONATION
				&& this.isShowLoyaltyCheckbox;
		},
		freeParkingEnabled() {
			const amount = Number(this.amount || 0);

			return !(this.neededAmountForFreeParking === null || amount < this.neededAmountForFreeParking);
		},
	},
	watch: {
		'$auth.user': {
			handler(user) {
				if (user) {
					this.firstName = user.first_name;
					this.lastName = user.last_name;
					this.secondName = user.second_name;
					this.email = user.email;
					this.mobilePhone = user.phone;
					this.setFieldsFromProfile();
				}
			},
			immediate: true,
		},
		fullCityInfo: {
			handler(value) {
				if (value && value.data) {
					this.city = value.data.settlement ?? value.data.city;
				} else {
					this.city = null;
				}
			},
			immediate: true,
		},
		'$route.hash': {
			handler(value) {
				if (value) {
					this.findRadio();

					const anchor = this.$route.hash.split('?')[0];
					if (window.location.hash.split('?')[0] === anchor) {
						const el = document.getElementById(anchor.slice(1));
						if (el) {
							this.$scrollTo(el);
						}
					}
				}
			},
		},
	},
	mounted() {
		this.$store.dispatch('profile/getRoles');

		this.dataActionCondition();
		this.setFieldsFromProfile();
		this.findRadio();

		if (this.block.id === 26353996) {
			this.amount = 500;
		}

		this.getParent();

		if (this.block.singular_buy === true) {
			if (this.block.sellable_programs.length === 1) {
				this.selectSellableProgramAndScroll(
					this.block.sellable_programs[0].id,
					false,
				);
			} else if (this.block.sellable_programs.length > 0) {
				this.selectedProgramsSingular = this.block.sellable_programs[0].id;
				this.onProgramSelect(this.block.sellable_programs[0].id);
			}
		} else if (this.block.sellable_programs.length > 0) {
			if (this.$route.query?.sellableprograms) {
				this.selectedProgramsSingular = this.$route.query?.sellableprograms;
			}
		}

		// if (this.block.fields.city.enabled) {
		// 	this.$nextTick(() => {
		// 		if (this.$refs.dadataCity) {
		// 			this.$refs.dadataCity.onInputBlur = () => {
		// 				this.$refs.dadataCity.inputFocused = false;
		// 				this.city = this.$refs.dadataCity.inputQuery;
		// 			};
		// 		}
		// 	});
		// }

		if (
			this.block.select_and_hide_price_lists === true
			&& this.block.sellable_programs.length > 0
		) {
			this.selectAllPrograms();
		}

		this.setRedirectUri(`${this.$route.fullPath}#empty-registration`);

		if (this.fetchedData.model === 'usefulContent') {
			this.generalLandingId = this.fetchedData.landing_id;
			this.select_landing_id = this.fetchedData.landing_id;
		}

		if (this.$auth.user && this.block.scenario === SCENARIOS.DONATION) {
			this.loadFreeParkingLevels();
		}
	},
	updated() {
		if (this.isCookiesAccepted !== true) {
			this.showCookiesDisclaimer();
		}
	},
	methods: {
		...mapActions({
			dispatchShowCookiesDisclaimer: 'cookiesDisclaimer/show',
			dispatchHideCookiesDisclaimer: 'cookiesDisclaimer/hide',
			showLoginPopup: 'authModal/showLogin',
			setRedirectUri: 'authModal/setRedirectUri',
		}),

		// eslint-disable-next-line complexity
		dataActionCondition() {
			if (this.block.scenario === 1 || this.block.scenario === 3) {
				if (this.fetchedData.id === 4382) return 'panels-smartform-personal-data-processing-and-public-offer-agreement';
				if (this.fetchedData.id === 1056) return 'panels-smartform-contract-data-processing-and-public-offer-agreement';
				return 'panels-smartform-personal-data-processing-agreement';
			}
			if (
				this.block.entity_id === 1406
				|| this.fetchedData.id === 10826
				|| (this.block.is_donation_scenario
					&& this.fetchedData.id === 7032)
				|| this.block.is_donation_scenario
				|| (this.block.scenario === SCENARIOS.DONATION && this.registrationAndDonation)
			) return 'panels-smartform-personal-data-processing-agreement-and-accept-donation-offer';
			if (this.block.scenario === SCENARIOS.BUY_PROGRAM) return 'panels-smartform-personal-data-processing-for-individuals-and-legal-entities-agreement';
			return 'panels-smartform-personal-data-processing-agreement';
		},

		async setFieldsFromProfile() {
			const profile = await this.getUserProfile();

			if (profile) {
				this.companyName = profile.company_name;
				this.jobTitle = profile.job_title;
				this.schoolCompany = profile.company_name;
				this.classPosition = profile.job_title;
				this.age = moment(profile.birthday).format('DD.MM.YYYY');
				this.student = profile.student;
				this.university = profile.university;
				this.faculty = profile.faculty;
				this.english_level = profile.level_knowledge_english;
				this.city = profile.location;

				// if (
				// 	this.$refs.dadataCity
				// 	&& !this.$refs.dadataCity.inputQuery
				// ) {
				// 	this.$refs.dadataCity.inputQuery = profile.location;
				// }
			}
		},
		getParent() {
			this.parentSlug = window.location.pathname.split('/');
		},
		updateValue(event) {
			const { value } = event.target;
			if (value < 500) {
				this.amount = 500;
			}
		},
		findRadio() {
			if (this.$route.hash) {
				const query = this.$route.hash;
				this.query = query.split('=').pop();
			} else {
				this.query = window.location.search.split('sellableprograms=')[1];
			}
		},
		changeAge() {
			if (this.ageFilledErr === null) return;
			this.ageFilledErr = !this.ageValidate;
		},
		getLandingId(e) {
			if (this.block.singular_buy === false) {
				const landingIds = [];

				e.forEach((element) => {
					const item = this.sellablePrograms.find(
						(item) => item.value === element,
					);

					if (item && item.landing_id !== null) {
						landingIds.push(item.landing_id);
					}
				});

				this.select_landing_id = landingIds;
			} else {
				const item = this.sellablePrograms.find(
					(item) => item.value === e,
				);

				if (item) {
					this.select_landing_id = item.landing_id;
				} else this.select_landing_id = this.generalLandingId;
			}
		},
		/**
		 * В отличие от покупки/пожертвования, мы не привязываемся к определённой сущности,
		 * поэтому сохраняем только текст.
		 * Далее он идёт в commentary при отправке в CRM.
		 *
		 * @param programTitle
		 */
		setRegistrationProgram(programTitle) {
			this.registrationProgram = programTitle;
		},

		// changeCity(dadata) {
		// 	if (dadata.data.city) {
		// 		this.city = dadata.data.city;
		// 	} else {
		// 		this.city = this.$refs.dadataCity.inputQuery;
		// 	}
		// },

		setPresentationNeeded(isNeeded) {
			this.isPresentationNeeded = isNeeded;
		},
		/**
		 * В event приходят выбранные программы в результате события клика,
		 * а не программно выставленные.
		 * В остальных случаях надо обращаться к this.selectedPrograms
		 *
		 * @param event
		 */
		refreshPrice(event) {
			if (this.block.scenario !== SCENARIOS.BUY_PROGRAM) {
				return;
			}

			let totalPrice = null;
			const selected = {};

			if (typeof event !== 'undefined' && typeof event === 'object') {
				event.forEach((e) => {
					selected[e] = e;
				});
			} else if (typeof event === 'number') {
				selected[event] = event;
			} else {
				this.selectedPrograms.forEach((e) => {
					selected[e] = e;
				});
			}

			this.block.sellable_programs.forEach((program) => {
				if (typeof selected[program.id] !== 'undefined') {
					totalPrice += parseInt(program.price, 10);
				}
			});

			if (
				this.block.batch_price
				&& this.selectedPrograms.length
					=== this.block.sellable_programs_all.length
			) {
				this.amount = this.block.batch_price;
			} else {
				this.amount = totalPrice;
			}
		},

		selectSellableProgramAndScroll(programId, doScroll) {
			let programAdded = false;
			if (programId) {
				this.selectedPrograms.forEach((selectedProgramId) => {
					if (programId === selectedProgramId) {
						programAdded = true;
					}
				});

				if (!programAdded && this.block.singular_buy === false) {
					this.selectedPrograms.push(programId);
				} else if (this.block.singular_buy === true) {
					this.selectedPrograms = [programId];
				}
			}

			this.refreshPrice();
			if (typeof doScroll !== 'undefined' && !doScroll) {
				return;
			}

			this.$el.scrollIntoView();
		},

		// временная функция для елки
		selectDonationGoods(programId, doScroll, price, goodID) {
			let programAdded = false;
			this.selectedPrograms.forEach((selectedProgramId, key) => {
				if (programId === selectedProgramId) {
					programAdded = true;
				}

				if (!selectedProgramId) {
					this.selectedPrograms.splice(key, 1);
				}
			});

			if (!programAdded && this.block.singular_buy === false) {
				this.selectedPrograms.push(programId);
			} else if (this.block.singular_buy === true) {
				this.selectedPrograms = [programId];
			}

			this.setAmount(price);
			this.comment = goodID;
			if (typeof doScroll !== 'undefined' && !doScroll) {
				return;
			}

			this.$el.scrollIntoView();
		},

		selectAllPrograms() {
			if (this.selectAllProgramsButtonChecked) {
				this.selectAllProgramsButtonChecked = false;
				this.selectedPrograms = [];
				this.refreshPrice();

				return;
			}

			const selectedPrograms = [];
			this.block.sellable_programs.forEach((program) => {
				selectedPrograms.push(program.id);
			});

			this.selectAllProgramsButtonChecked = true;
			this.selectedPrograms = selectedPrograms;
			this.getLandingId(selectedPrograms);
			this.refreshPrice();
		},

		onProgramSelect(event) {
			this.refreshPrice(event);
			this.getLandingId(event);
			this.selected = event;
		},

		resetAmountButtons() {
			for (const amountButtonStatesKey in this.amountButtonStates) {
				this.amountButtonStates[amountButtonStatesKey] = false;
			}
		},

		onAmountInput(event) {
			this.resetAmountButtons();
			if (this.block.id === 26353996) {
				this.updateValue(event);
			}
		},

		setAmount(amount) {
			this.amount = amount;
			this.resetAmountButtons();
			this.amountButtonStates[amount] = true;
		},

		phoneUpdate(payload) {
			this.countryCode = payload.countryCode;
			this.showPhoneValidationError = !payload.isValid;
			this.isMobilePhoneValid = payload.isValid;
			this.mobilePhoneInternational = payload.formatInternational;
		},

		selectPaymentMethod(paymentMethod) {
			this.paymentMethod = paymentMethod;
		},

		selectPaymentProvider(paymentProvider) {
			this.paymentProvider = paymentProvider;
		},

		setPaymentMethodDropdownText(target) {
			this.paymentMethodText = target.textContent;
			this.selectedItem = target.textContent;
		},

		// eslint-disable-next-line complexity
		async submit() {
			if (!this.isCookiesAccepted) {
				this.isErrorCookie = true;
				await this.hideCookiesDisclaimer();
				this.showCookiesDisclaimer();
				this.submittedStatus = false;
			} else {
				this.submittedStatus = true;
				this.isErrorCookie = false;
				if (this.mobilePhone && this.mobilePhone.length > 0) {
					// чтобы предзаполненный номер автоматически обработался
					this.$refs.phoneInput.emitValues({
						phoneNumber: this.mobilePhone,
						countryCode: this.countryCode,
					});
				}

				this.generalLandingId = this.fetchedData.landing_id;

				if (this.block.landing_id_custom_page) {
					this.generalLandingId = this.block.landing_id_custom_page;
				}

				this.$v.$touch();
				if (this.block.fields.age.enabled && this.age !== null) {
					this.ageFilledErr = !this.ageValidate;
				}
				if (!this.isMobilePhoneValid) {
					this.showPhoneValidationError = true;
				}
				// if (
				// 	this.block.fields.participation_format
				// 	&& this.block.fields.participation_format.enabled
				// 	&& this.participation_format === 'Offline'
				// ) {
				// 	this.certificateFilledErr = !(this.certificate_number.length === 12)
				// 		&& !(this.pcr_test_number.length === 8);
				// 	this.pcrTestNumberFilledErr = !(this.pcr_test_number.length === 8)
				// 		&& this.certificateFilledErr;
				// }
				if (
					this.$v.$invalid
					|| this.ageFilledErr
					|| !this.isMobilePhoneValid
					|| (this.certificateFilledErr && this.pcrTestNumberFilledErr)
				) {
					this.submittedStatus = false;
					return;
				}
				this.showPhoneValidationError = false;

				this.setAgreementFields();

				const leadData = await this.makeLeadData();

				if (
					this.block.landing_id_custom_page
					&& !this.block.is_goods_donation
				) {
					this.submitLead(leadData);
					return;
				}

				if (
					!this.registrationAndDonation
					&& this.block.scenario === SCENARIOS.DONATION
				) {
					this.submitRegistration(leadData);
					return;
				}

				switch (this.block.scenario) {
				case SCENARIOS.REGISTRATION:
					this.submitRegistration(leadData);
					break;

				case SCENARIOS.BUY_PROGRAM:
				case SCENARIOS.DONATION:
					this.submitPayment(leadData);
					break;
				}
			}
		},

		async loadFreeParkingLevels() {
			const {
				data: levels,
				customer_level,
			} = await this.$api.getLoyaltyLevels();

			this.freeParkingLevels = levels.filter((item) => item.free_parking_donation)
				.map((item) => ({
					name: item.name,
					donation: Number(item.free_parking_donation).toLocaleString(),
					isCustomerLevel: customer_level === item.id,
				}));

			if (customer_level) {
				const freeParkingDonation = levels.filter((item) => item.id === customer_level)[0]?.free_parking_donation ?? null;

				if (freeParkingDonation) {
					this.neededAmountForFreeParking = Number(freeParkingDonation);
				}
			}
		},

		makeLeadData() {
			const clientID = typeof yaCounter994527 !== 'undefined'
				? yaCounter994527.getClientID()
				: '';
			const { UAClientID } = window;
			const BpmRef = this.$cookies.get('bpmRef') || '';
			const BpmHref = this.$cookies.get('bpmHref') || '';
			const roistatVisitId = this.$cookies.get('roistat_visit') || '';

			const leadData = {
				need_register_loyalty: this.isLoyal,
				free_parking: this.free_parking,
				have_free_parking_pass: this.have_free_parking_pass,
				lost_free_parking_pass: this.lost_free_parking_pass,
				car_number: this.car_number,
				admissionId: this.admissionId,
				landingId: this.generalLandingId,
				select_landing_id: this.select_landing_id,
				leadFields: {
					TsContactGivenName: this.firstName,
					TsContactSurname: this.lastName,
					Email: this.email,
					TsAdditionalInformation: '',
					MobilePhone: this.mobilePhoneInternational,
					SkGAClientId:
						typeof UAClientID !== 'undefined' ? UAClientID : '',
					SkYMClientId:
						typeof clientID !== 'undefined' ? clientID : '',
					roistatVisitId:
						typeof roistatVisitId !== 'undefined' ? roistatVisitId : '',
					BpmRef,
					BpmHref,
					sklLloyaltyProgramRegistration: this.isLoyal,
					sklAgreeSubscribeLookup: this.agreements.marketing_emails ? 'да' : 'нет',
					sklAgreedPersonalData: this.agreements.personal_data,
					sklTargetAction: getTargetAction(EVENT_FORM_SCENARIOS.REGISTRATION, this.block.scenario, this.fetchedData.typology),
				},
			};

			if (this.block.landing_id_custom_page !== null) {
				leadData.landingId = this.block.landing_id_custom_page;
			}

			if (leadData.leadFields.SkYMClientId === null) {
				leadData.leadFields.SkYMClientId = '';
			}

			if (
				this.block.fields.company_name.enabled
				&& this.companyName !== null
			) {
				leadData.leadFields.Account = this.companyName;
			}

			if (
				this.block.fields.school_company.enabled
				&& this.schoolCompany !== null
			) {
				leadData.leadFields.Account = this.companyName;
			}

			if (this.block.fields.job_title.enabled && this.jobTitle !== null) {
				leadData.leadFields.FullJobTitle = this.jobTitle;
			}

			if (
				this.block.fields.class_position.enabled
				&& this.classPosition !== null
			) {
				leadData.leadFields.FullJobTitle = this.jobTitle;
			}

			if (this.block.fields.city.enabled && this.city !== null) {
				leadData.leadFields.City = this.city;
			}

			if (
				this.block.fields.second_name.enabled
				&& this.secondName !== null
			) {
				leadData.leadFields.TsContactMiddleName = this.secondName;
			}

			if (this.comment !== null) {
				leadData.leadFields.TsAdditionalInformation = this.comment;
			}

			if (
				this.block.fields.participation_format
				&& this.block.fields.participation_format.enabled
			) {
				leadData.leadFields.TsAdditionalInformation += ` Формат участия: ${this.participation_format}`;

				// if (this.certificate_number) {
				// 	leadData.leadFields.TsAdditionalInformation += ` Номер сертификата: ${this.certificate_number}`;
				// }
				//
				// if (this.pcr_test_number) {
				// 	leadData.leadFields.TsAdditionalInformation += ` Номер заказа на ПЦР тест: ${this.pcr_test_number}`;
				// }
			}

			if (
				this.block.fields.student.enabled
				&& this.block.fields.comment.enabled
				&& this.comment !== null
				&& this.student !== null
			) {
				leadData.leadFields.TsAdditionalInformation += `${this.comment}. Является студентом - ${this.student}`;
			} else if (this.block.fields.student.enabled) {
				leadData.leadFields.TsAdditionalInformation += `Является студентом - ${this.student}`;
			}

			if (this.block.fields.section.enabled) {
				leadData.leadFields.TsAdditionalInformation += ` Профиль: ${this.section};`;
			}

			if (this.block.fields.legal.enabled) {
				leadData.leadFields.TsAdditionalInformation += ` Юридическое лицо: ${this.legal};`;
			}

			if (this.block.fields.planned_participation.enabled) {
				leadData.leadFields.TsAdditionalInformation += ` Планируемое участие: ${this.planned_participation};`;
			}

			if (this.block.fields.number_participants.enabled) {
				leadData.leadFields.TsAdditionalInformation += ` Количество участников:${this.number_participants};`;
			}

			if (this.block.fields.city.enabled) {
				leadData.leadFields.TsAdditionalInformation += ` Город: ${this.city}`;
			}

			if (!this.registrationAndDonation) {
				leadData.leadFields.TsAdditionalInformation
					+= ' Простая регистрация без оплаты и пожертвований';
			}

			if (
				this.block.fields.english_level.enabled
				&& this.english_level !== null
			) {
				leadData.leadFields.TsEnglishKnowledge = `${this.english_level}`;
			}

			if (
				this.block.fields.university.enabled
				&& this.university !== null
			) {
				leadData.leadFields.University = `${this.university}`;
			}

			if (this.block.fields.faculty.enabled && this.faculty !== null) {
				leadData.leadFields.Faculty = `${this.faculty}`;
			}

			if (
				this.block.fields.age.enabled
				&& this.age !== null
				&& this.age !== ''
			) {
				leadData.leadFields.TsBirthDate = `${this.age}`;
			}

			leadData.leadFields.Commentary = '';

			if (this.selectedPrograms.length !== 0) {
				leadData.leadFields.Commentary += ' | Программы: ';
				const selectedPrograms = {};
				this.selectedPrograms.forEach((e) => (selectedPrograms[e] = e));

				this.block.sellable_programs.forEach((program) => {
					if (typeof selectedPrograms[program.id] !== 'undefined') {
						leadData.leadFields.Commentary += `${program.title},`;
					}
				});
			}

			if (this.donationName === true) {
				leadData.leadFields.TsAdditionalInformation
					+= 'НЕ отображать на странице «Мы благодарим»';
			}

			// todo переделать на определение сущности.
			if (this.block.scenario !== SCENARIOS.DONATION) {
				leadData.leadFields.Commentary += ` | ${
					this.fetchedData.title
				} | ${
					this.fetchedData.project_title
						? this.fetchedData.project_title
						: ''
				} | ${
					this.fetchedData.event_date
						? this.fetchedData.event_date
						: ''
				}`;

				if (this.fetchedData.issue != null) {
					leadData.leadFields.Commentary += ` | ${this.fetchedData.issue.title}`;
				}
			}

			if (this.registrationProgram !== null) {
				leadData.leadFields.Commentary += ` | Программа: ${this.registrationProgram}`;
			}

			if (this.isPresentationNeeded === true) {
				leadData.leadFields.Commentary += ' | Требуется презентация';
			}

			return leadData;
		},
		submitPayment(leadData) {
			const paymentData = {
				lead: leadData,
				amount: this.amount,
				vat: false,
				language: 'ru',
				paymentProvider: this.paymentProvider,
				paymentMethod: this.paymentMethod,
				scenario: this.block.scenario,
				items: this.selectedPrograms,
				landingTitle: this.fetchedData.title,
				entityId: this.block.entity_id,
				entityName: this.block.entity_name,
				secret: this.block.secret,
				promoCode: this.loanPromoCode,
				blockIdEncrypted: this.block.id,
			};

			if (
				paymentData.paymentProvider !== null
				&& paymentData.paymentMethod !== null
			) {
				this.$api.axios
					.post(
						`${process.env.baseURL}/api/payment/yandex/create`,
						paymentData,
					)
					.then((response) => {
						if (response.data.success === false) {
							alert(
								'Произошла ошибка при создании оплаты. Пожалуйста, попробуйте позже.',
							);
						}
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});

						window.location.href = response.data.payment_url;
					})
					.catch((error) => {
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});
						if (error.response.status !== 422) {
							alert(
								'Произошла ошибка при создании оплаты. Пожалуйста, попробуйте позже.',
							);
						} else {
							alert(error.response.data.message);
						}
						this.submittedStatus = false;
					});
			} else {
				this.$axios({
					url: `${process.env.baseURL}/api/v2/leadsintegration/save`,
					data: leadData,
					method: 'post',
					contentType: 'application/x-www-form-urlencoded',
					crossDomain: true,
					async: false,
				})
					.then(() => {
						this.$api.axios
							.post(
								`${process.env.baseURL}/api/v2/loan-applications/create`,
								paymentData,
							)
							.then((response) => {
								if (response.data.success === false) {
									alert(
										'Произошла ошибка при создании оплаты. Пожалуйста, попробуйте позже.',
									);
								}
								window.dataLayer = window.dataLayer || [];
								dataLayer.push({
									event: 'form_send',
									form_data: leadData,
								});

								let extendedRegistrationString = '';
								if (response.data.isExtendedRegistration) {
									extendedRegistrationString = `&uuid=${response.data.uuid}`;
								}

								if (this.fetchedData.typology_slug) {
									top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
										+ `?openid=${
											this.select_landing_id
											|| this.generalLandingId
										}`;
								}
								if (
									this.fetchedData.typology_slug
									&& this.block.scenario === SCENARIOS.DONATION
								) {
									top.location.href = '/success/'
										+ `?invoiceId=${query.invoiceId}`;
								}
								if (this.fetchedData.model === 'page') {
									if (this.block.scenario === SCENARIOS.DONATION) {
										top.location.href = '/success/'
											+ `?invoiceId=${query.invoiceId}`;
									}
									if (
										this.fetchedData?.parent_links
											?.length
										&& this.block.scenario !== SCENARIOS.DONATION
									) {
										top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
											+ `?openid=${
												this.select_landing_id
												|| this.generalLandingId
											}${extendedRegistrationString}`;
									} else {
										top.location.href = `/${this.fetchedData.slug}/success/`
											+ `?openid=${
												this.select_landing_id
												|| this.generalLandingId
											}`;
									}
								}
							})
							.catch(() => {
								alert(
									'Произошла ошибка при создании оплаты. Пожалуйста, попробуйте позже.',
								);
								this.submittedStatus = false;
							});
					})
					.catch(() => {
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});
						if (this.fetchedData.typology_slug) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?openid=${
									this.select_landing_id
									|| this.generalLandingId
								}`;
						}
						if (
							this.fetchedData.typology_slug
							&& this.block.scenario === SCENARIOS.DONATION
						) {
							top.location.href = '/success/' + `?invoiceId=${query.invoiceId}`;
						}
						if (this.fetchedData.model === 'page') {
							if (this.block.scenario === SCENARIOS.DONATION) {
								top.location.href = '/success/'
									+ `?invoiceId=${query.invoiceId}`;
							}
							if (
								this.fetchedData?.parent_links?.length
								&& this.block.scenario !== SCENARIOS.DONATION
							) {
								top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}${extendedRegistrationString}`;
							} else {
								top.location.href = `/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}`;
							}
						}
					});
			}
		},

		submitLead(leadData) {
			if (this.block.dont_send_lead) {
				if (this.block.entity_id === 282) {
					this.$axios({
						url: `${process.env.baseURL}/api/corona/sign-up`,
						data: {
							need_register_loyalty: this.isLoyal,
							is_email_disabled: this.block.is_email_disabled,
							eventId: this.fetchedData.id,
							entityId: 282,
							sellablePrograms: this.selectedPrograms,
							...leadData,
							scenario: EVENT_FORM_SCENARIOS.REGISTRATION,
							additionalUserData: {
								comment: this.comment,
								age: this.age,
								university: this.university,
								faculty: this.faculty,
								isStudent: this.student,
								userId: this.$auth.loggedIn
									? this.$auth.$state.user.id
									: null,
							},
						},
						method: 'post',
						contentType: 'application/x-www-form-urlencoded',
						crossDomain: true,
						async: false,
					}).then(() => {
						this.$bvModal.show('lead_ok');
						this.submittedStatus = false;
					});
				}
			} else {
				this.$axios({
					url: `${process.env.baseURL}/api/v2/leadsintegration/save`,
					data: leadData,
					method: 'post',
					contentType: 'application/x-www-form-urlencoded',
					crossDomain: true,
					async: false,
				}).then(() => {
					dataLayer.push({
						event: 'form_send',
						form_data: leadData,
					});
					if (
						this.block.entity_id === 282
						|| this.fetchedData.model === 'usefulContent'
					) {
						this.$axios({
							url: `${process.env.baseURL}/api/corona/sign-up`,
							data: {
								need_register_loyalty: this.isLoyal,
								is_email_disabled: this.block.is_email_disabled,
								eventId: this.fetchedData.id,
								entityId: 282,
								sellablePrograms: this.selectedPrograms,
								...leadData,
								scenario: EVENT_FORM_SCENARIOS.REGISTRATION,
								additionalUserData: {
									comment: this.comment,
									age: this.age,
									university: this.university,
									faculty: this.faculty,
									isStudent: this.student,
									userId: this.$auth.loggedIn
										? this.$auth.$state.user.id
										: null,
								},
							},
							method: 'post',
							contentType: 'application/x-www-form-urlencoded',
							crossDomain: true,
							async: false,
						});
					}
					this.$bvModal.show('lead_ok');
					this.submittedStatus = false;
				});
			}
		},

		submitRegistration(leadData) {
			if (this.block.dont_send_lead) {
				this.$api
					.axios({
						url: `${process.env.baseURL}/api/corona/sign-up`,
						data: {
							need_register_loyalty: this.isLoyal,
							is_email_disabled: this.block.is_email_disabled,
							model: this.fetchedData.model,
							eventId: this.fetchedData.id,
							sellablePrograms: this.selectedPrograms,
							...leadData,
							scenario: EVENT_FORM_SCENARIOS.REGISTRATION,
							additionalUserData: {
								comment: this.comment,
								age: this.age,
								university: this.university,
								faculty: this.faculty,
								isStudent: this.student,
								userId: this.$auth.loggedIn
									? this.$auth.$state.user.id
									: null,
							},
						},
						method: 'post',
						contentType: 'application/x-www-form-urlencoded',
						crossDomain: true,
						async: false,
					})
					.then((response) => {
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});

						let extendedRegistrationString = '';
						if (response.data.isExtendedRegistration) {
							extendedRegistrationString = `&uuid=${response.data.uuid}`;
						}
						if (this.fetchedData.typology_slug) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?openid=${
									this.select_landing_id
									|| this.generalLandingId
								}`;
						}
						if (
							this.fetchedData.typology_slug
							&& this.block.scenario === SCENARIOS.DONATION
						) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?invoiceId=${query.invoiceId}`;
						}
						if (this.fetchedData.model === 'page') {
							if (this.block.scenario === SCENARIOS.DONATION) {
								top.location.href = '/success/'
									+ `?invoiceId=${query.invoiceId}`;
							}
							if (
								this.fetchedData?.parent_links?.length
								&& this.block.scenario !== SCENARIOS.DONATION
							) {
								top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}${extendedRegistrationString}`;
							} else {
								top.location.href = `/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}`;
							}
						}

						if (this.fetchedData.model === 'usefulContent') {
						}
					})
					.catch((error) => {
						console.error(error);
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});
						if (this.fetchedData.typology_slug) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?openid=${
									this.select_landing_id
									|| this.generalLandingId
								}`;
						}
						if (
							this.fetchedData.typology_slug
							&& this.block.scenario === SCENARIOS.DONATION
						) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?invoiceId=${query.invoiceId}`;
						}
						if (this.fetchedData.model === 'page') {
							if (this.block.scenario === SCENARIOS.DONATION) {
								top.location.href = '/success/'
									+ `?invoiceId=${query.invoiceId}`;
							}
							if (
								this.fetchedData?.parent_links?.length
								&& this.block.scenario !== SCENARIOS.DONATION
							) {
								top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}${extendedRegistrationString}`;
							} else {
								top.location.href = `/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}`;
							}
						}
					});
			} else {
				this.$axios({
					url: `${process.env.baseURL}/api/v2/leadsintegration/save`,
					data: leadData,
					method: 'post',
					contentType: 'application/x-www-form-urlencoded',
					crossDomain: true,
					async: false,
				})
					.then(() => {
						this.$api
							.axios({
								url: `${process.env.baseURL}/api/corona/sign-up`,
								data: {
									need_register_loyalty: this.isLoyal,
									is_email_disabled:
										this.block.is_email_disabled,
									model: this.fetchedData.model,
									eventId: this.fetchedData.id,
									sellablePrograms: this.selectedPrograms,
									...leadData,
									scenario: EVENT_FORM_SCENARIOS.REGISTRATION,
									additionalUserData: {
										comment: this.comment,
										age: this.age,
										university: this.university,
										faculty: this.faculty,
										isStudent: this.student,
										userId: this.$auth.loggedIn
											? this.$auth.$state.user.id
											: null,
									},
								},
								method: 'post',
								contentType:
									'application/x-www-form-urlencoded',
								crossDomain: true,
								async: false,
							})
							.then((response) => {
								window.dataLayer = window.dataLayer || [];
								dataLayer.push({
									event: 'form_send',
									form_data: leadData,
								});

								let extendedRegistrationString = '';
								if (response.data.isExtendedRegistration) {
									extendedRegistrationString = `&uuid=${response.data.uuid}`;
								}
								if (this.fetchedData.typology_slug) {
									top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
										+ `?openid=${
											this.select_landing_id
											|| this.generalLandingId
										}`;
								}
								if (
									this.fetchedData.typology_slug
									&& this.block.scenario === SCENARIOS.DONATION
								) {
									top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
										+ `?invoiceId=${query.invoiceId}`;
								}
								if (this.fetchedData.model === 'page') {
									if (this.block.scenario === SCENARIOS.DONATION) {
										top.location.href = '/success/'
											+ `?invoiceId=${query.invoiceId}`;
									}
									if (
										this.fetchedData?.parent_links
											?.length
										&& this.block.scenario !== SCENARIOS.DONATION
									) {
										top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
											+ `?openid=${
												this.select_landing_id
												|| this.generalLandingId
											}${extendedRegistrationString}`;
									} else {
										top.location.href = `/${this.fetchedData.slug}/success/`
											+ `?openid=${
												this.select_landing_id
												|| this.generalLandingId
											}`;
									}
								}

								if (
									this.fetchedData.model === 'usefulContent'
								) {
									top.location.href = `/article/${this.fetchedData.slug}/ok-success`;
								}
							})
							.catch((error) => {
								console.error(error);
								window.dataLayer = window.dataLayer || [];
								dataLayer.push({
									event: 'form_send',
									form_data: leadData,
								});
							});
					})
					.catch((error) => {
						window.dataLayer = window.dataLayer || [];
						dataLayer.push({
							event: 'form_send',
							form_data: leadData,
						});
						if (this.fetchedData.typology_slug) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?openid=${
									this.select_landing_id
									|| this.generalLandingId
								}`;
						}
						if (
							this.fetchedData.typology_slug
							&& this.block.scenario === SCENARIOS.DONATION
						) {
							top.location.href = `/${this.fetchedData.typology_slug}/${this.fetchedData.slug}/ok-success/`
								+ `?invoiceId=${query.invoiceId}`;
						}
						if (this.fetchedData.model === 'page') {
							if (this.block.scenario === SCENARIOS.DONATION) {
								top.location.href = '/success/'
									+ `?invoiceId=${query.invoiceId}`;
							}
							if (
								this.fetchedData?.parent_links?.length
								&& this.block.scenario !== SCENARIOS.DONATION
							) {
								top.location.href = `/${this.parentSlug[1]}/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}${extendedRegistrationString}`;
							} else {
								top.location.href = `/${this.fetchedData.slug}/success/`
									+ `?openid=${
										this.select_landing_id
										|| this.generalLandingId
									}`;
							}
						}
					});
			}
		},
		showCookiesDisclaimer() {
			const isRejectable = false;
			this.dispatchShowCookiesDisclaimer(isRejectable);
		},
		hideCookiesDisclaimer() {
			this.dispatchHideCookiesDisclaimer();
		},
		showLoginModal() {
			if (this.isShowLoginModal && !this.$auth.loggedIn) {
				this.isShowLoginModal = false;
				this.showLoginPopup();
			}
		},
	},
};
</script>

<style lang="scss">
//	@import "~/assets/styles/components/blocks/event-form-panel";
@import "~/assets/styles/components/home/panel-smart-form";
</style>

<style lang="scss">
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.panel_form {
	.dadata-form {
		input {
			width: 100%;
			border: none !important;
			background: none;
			border-bottom: 1px #000000 solid !important;
			padding: .5rem 0rem .5rem 0rem !important;
			font-size: 1rem !important;
			color: $body-color !important;
			font-weight: 400 !important;
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
		}
	}
}

.cookie-error {
	width: 100%;
	font-size: 100%;
	color: #dc3545;
}

.input-tel__label {
	top: -8px !important;
}

.university-crutched {
	padding: 0.375rem 0.8rem 0.375rem 0;
	-webkit-appearance: none;
}

.country-selector.no-flags {
	.country-selector__input {
		padding-left: 0 !important;
	}
}

.input-city-dadata {
	&__container {
		width: 100%;
		position: relative;
	}

	&__input {
		// Styles copied from bootstrap/_forms.scss (.form-control class)

		display: block;
		width: 100%;
		height: $input-height;
		padding: $input-padding-y $input-padding-x;
		font-family: $input-font-family;
		@include font-size($input-font-size);
		font-weight: $input-font-weight;
		line-height: $input-line-height;
		color: $input-color;
		background-color: $input-bg;
		background-clip: padding-box;
		border: $input-border-width solid $input-border-color;
		border-width: 0 0 1px 0;

		// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
		@include border-radius($input-border-radius, 0);

		@include box-shadow($input-box-shadow);
		@include transition($input-transition);

		// Unstyle the caret on `<select>`s in IE10+.
		&::-ms-expand {
			background-color: transparent;
			border: 0;
		}

		// Remove select outline from select box in FF
		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $input-color;
		}

		// Customize the `:focus` state to imitate native WebKit styles.
		@include form-control-focus($ignore-warning: true);

		// Placeholder
		&::placeholder {
			color: $input-placeholder-color;
			// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
			opacity: 1;
		}

		// Disabled and read-only inputs
		//
		// HTML5 says that controls under a fieldset > legend:first-child won't be
		// disabled if the fieldset is disabled. Due to implementation difficulty, we
		// don't honor that edge case; we style them as disabled anyway.
		&:disabled,
		&[readonly] {
			background-color: $input-disabled-bg;
			// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
			opacity: 1;
		}
	}

	&__suggestions {
		position: absolute;
		z-index: 10;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;

		&-item {
			padding: 10px;
			cursor: pointer;
			transition: 0.3s;

			&-highlight {
				background-color: #455fed;
				color: #fff;
			}

			&:hover {
				background-color: #455fed;
				color: #fff;
			}

			&_current {
				background-color: #455fed;
				color: #fff;
			}
		}
	}
}

/* --- steps --- */

.form-steps {
	width: 100%;
	height: 100%;
	border: 0;
	background-color: transparent;
	border: 2px solid $blue;
	color: $blue;
	padding: 2rem 1rem;
	outline: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;

	&:focus,
	&:hover {
		outline: 0;
		box-shadow: none;
		border: 2px solid $link-hover-color;
		background-color: $link-hover-color;
		color: $white;
	}

	&.active {
		background-color: $blue;
		color: $white;

		&:hover {
			background-color: $link-hover-color;
			color: $white;
		}
	}

	.description {
		margin-top: 0.5rem;
		font-weight: 500;
	}
}

.loanPromoCode {
	&.custom-select {
		background-position: right 1rem center;
		border: 1px solid $body-color !important;
	}
}
</style>
