import { mapActions, mapState } from 'vuex';

export default {
	computed: {
		...mapState({ userProfileValues: (state) => state.profile.userProfile }),
	},
	methods: {
		...mapActions({
			getUserProfile: 'profile/getProfile',
		}),
	},
};
