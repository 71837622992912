<template>
	<vue-suggestion
		v-model="suggestValue"
		:name="inputName"
		:items="suggestions"
		:disabled="disabled"
		:item-template="DaDataItem"
		:set-label="setLabel"
		:input-options="inputOptions"
		:style-classes="styleClasses"
		@changed="onInputChange"
		@selected="suggestValue = $event.value"
		@blur="$emit('blur')"
	/>
</template>

<script>
import { VueSuggestion } from 'vue-suggestion';
import DaDataDefaultItem from '~/components/daData/DadataItems/DaDataDefaultItem.vue';
import debounce from 'lodash/debounce';

export default {
	name: 'DaDataInput',
	components: {
		VueSuggestion,
	},
	props: {
		/**
		 * @see https://dadata.ru/api/suggest/
		 */
		suggestType: {
			type: String,
			required: true,
		},
		value: {
			type: [String, Object],
			default: () => '',
		},
		inputName: {
			type: String,
			required: true,
		},
		setLabel: {
			type: Function,
			default: (item) => item.value,
		},
		daDataItem: {
			type: Object,
			default: () => DaDataDefaultItem,
		},
		initialValue: {
			type: [String, Object],
			default: null,
		},
		filterSuggestions: {
			type: Function,
			default: (item) => item,
		},
		/**
		 * @see https://github.com/iamstevendao/vue-suggestion/blob/master/src/components/vue-suggestion.vue
		 */
		inputOptions: {
			type: Object,
			default: () => ({}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		styleClasses: {
			type: [Object, String, Number],
			default: '',
		},
		suggestionOptions: {
			type: Object,
			default: () => ({}),
		},
		minLen: {
			type: Number,
			default: 2,
		},
		requestOptions: {
			type: Object,
			default: () => {},
		},
		isSetInitialValue: {
			type: Boolean,
			default: false,
		},
		isOwnTypeValue: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			suggestions: [],
			suggestValue: '',
			DaDataItem: this.daDataItem,
			isOnceCall: true,
		};
	},
	watch: {
		initialValue: {
			async handler(value) {
				if (value && this.isOnceCall) {
					await this.suggest(value);
					if (this.isSetInitialValue && this.suggestions.length > 0 && this.suggestions[0].value === this.initialValue) {
						[this.suggestValue] = this.suggestions;
					} else if (this.isSetInitialValue) {
						this.suggestValue = this.initialValue;
					} else if (this.suggestions.length > 0) {
						[this.suggestValue] = this.suggestions;
					} else {
						this.$emit('input', null);
					}
				}
			},
			immediate: true,
		},
		suggestValue: {
			handler(value) {
				if (value) {
					if (value.data) {
						this.$emit('input', { value: value.value, data: value.data });
					} else {
						this.$emit('input', { value });
					}
				} else {
					this.$emit('input', null);
				}
			},
		},
	},
	methods: {
		debouncedData: debounce(async function (payload = {}) {
			this.suggest(payload);
		}, 500),
		async onInputChange(value) {
			await this.debouncedData(value);
			if (!this.isOwnTypeValue) {
				this.$emit('input', null);
			} else {
				this.suggestValue = value;
			}
		},
		async suggest(value) {
			if (value) {
				this.isOnceCall = false;
				this.suggestions = await this.$api.axios.$post('/api/v2/daData/suggest', {
					value,
					type: this.suggestType,
					additionalArguments: {
						...this.requestOptions,
						locations: [{ country: '*' }],
						count: 20,
						language: this.$i18n.locale === 'ru' ? 'ru' : 'en',
					},
				});
				// this.suggestions = this.filterSuggestions(this.suggestions);
			}
		},
	},
};
</script>

<style lang="scss">
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.vue-suggestion {

    .vs {
    &__container {
      position: relative;
      width: 100%;
    }

    &__input {
      // Styles copied from bootstrap/_forms.scss (.form-control class)

      display: block;
      width: 100%;
  //    height: $input-height;
  //    padding: $input-padding-y $input-padding-x;
      font-family: $input-font-family;
      @include font-size($input-font-size);
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: $input-border-width solid $input-border-color;

      // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
      @include border-radius($input-border-radius, 0);

      @include box-shadow($input-box-shadow);
      @include transition($input-transition);

      // Unstyle the caret on `<select>`s in IE10+.
      &::-ms-expand {
        background-color: transparent;
        border: 0;
      }

      // Remove select outline from select box in FF
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
      }

      // Customize the `:focus` state to imitate native WebKit styles.
      @include form-control-focus($ignore-warning: true);

      // Placeholder
      &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
      }

      // Disabled and read-only inputs
      //
      // HTML5 says that controls under a fieldset > legend:first-child won't be
      // disabled if the fieldset is disabled. Due to implementation difficulty, we
      // don't honor that edge case; we style them as disabled anyway.
      &:disabled,
      &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
      }

      // width: 100%;
      // border: none;
      // border-bottom: 1px solid #000;
      // background-color: transparent;
      // color: #000;
      // font-size:16px;
      // padding: 6px 16px;
      // height:38px;
      // line-height:24px;

      // &::placeholder {
      // 	color: #000;
      // }

      // &:focus {
      // 	outline: none;
      // 	&::placeholder {
      // 		color: transparent;
      // 		opacity: 0;
      // 	}
      // }
    }

    &__list {
      position: absolute;
      z-index: 100;
      width: 100%;
			max-height: 400px;
			overflow-y: scroll;
      display: flex;
      flex-direction: column;
      background-color: #fff;

      &-item {
        padding: 10px;
        cursor: pointer;
        transition: 0.3s;
        color: black;
        border-bottom: 1px solid $border-color;

        &-highlight {
          background-color: $blue;
          color: #fff;
        }

        &:hover {
          background-color: $blue;
          color: #fff;
        }

        &_current {
          background-color: $blue;
          color: #fff;
        }
      }
    }
  }
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
