import { mapActions } from 'vuex';

// @vue/component
export default {
	data() {
		return {
			agreements: {
				personal_data: false,
				marketing_emails: false,
				can_view_alumni_directory: false,
			},
			agreements_validation: {
				personal_data: {
					checked: (value) => value === true,
				},
			},
			showPersonalData: false,
			showMarketingEmails: false,
			showCanViewAlumniDirectory: false,
		};
	},
	watch: {
		'$auth.user': {
			handler(user) {
				if (user) {
					this.fetchAgreements();
				}
			},
		},
	},
	created() {
		this.fetchAgreements();
	},
	methods: {
		fetchAgreements() {
			this.agreements.personal_data = this.$auth.user?.is_agrees_to_processing_personal_data
				|| false;
			this.showPersonalData = !this.agreements.personal_data;

			this.agreements.marketing_emails = this.$auth.user?.is_agrees_to_marketing_emails
				|| false;
			this.showMarketingEmails = !this.agreements.marketing_emails;

			this.agreements.can_view_alumni_directory = this.$auth.user?.is_agrees_to_can_view_alumni_directory
				|| false;
			this.showCanViewAlumniDirectory = !this.agreements.can_view_alumni_directory;
		},
		async setAgreementFields() {
			const { user } = this.$auth;

			if (!user) {
				console.log('no user to set agreement fields');

				return;
			}

			const data = {
				is_agrees_to_processing_personal_data: this.agreements.personal_data,
				is_agrees_to_marketing_emails: this.agreements.marketing_emails,
				is_agrees_to_can_view_alumni_directory: this.agreements.can_view_alumni_directory,
			};

			await this.$api.setAgreementFields(data, user.id).then(() => {
				if (this.form) {
					this.form.message = 'Обновлено';
				}

				this.$auth.fetchUser({ url: 'api/auth/users/self?full=true' }).then(() => {
					this.fetchAgreements();
				});
			});
		},
		async setAgreementFieldsByHash(userData) {
			const agreements = {
				is_agrees_to_processing_personal_data: this.agreements.personal_data,
				is_agrees_to_marketing_emails: this.agreements.marketing_emails,
			};

			const data = { ...userData, ...agreements };

			await this.$api.setAgreementFieldsByHash(data).then(() => {
				if (this.form) {
					this.form.message = 'Обновлено';
				}
			});
		},
		async fetchUserByHash() {
			if (!this.$auth.user) {
				const { query } = this.$route;

				await this.$api.fetchUserByHash(query).then((response) => {
					const { data } = response;
					this.id = data.id;
					this.email = data.email;
					this.agreements.personal_data = data.is_agrees_to_processing_personal_data;
					this.agreements.marketing_emails = data.is_agrees_to_marketing_emails;
				}).catch((error) => {
					this.form.error = error.message;
				});
			}
		},
		async sendRequestCanViewAlumniDirectory() {
			if (!this.isAlumni) {
				return;
			}
			const changedData = this.$auth.user;
			const formData = new FormData();
			const visibility = {
				first_name: 1,
				last_name: 1,
				profile_photo: 1,
				email: 1,
				phone: 1,
				telegram: 1,
			};
			this.createFormData(formData, changedData, 'fields');
			this.createFormData(formData, { is_agrees_to_can_view_alumni_directory: this.agreements.can_view_alumni_directory, ...visibility }, 'visibility');
			formData.append('is_agrees_to_can_view_alumni_directory', this.agreements.can_view_alumni_directory ? 1 : 0);
			await this.$api.setRequestAccessToDirectory(formData);
		},
	},
};
