<template>
	<div>
		<span>{{ item.value }}</span>
	</div>
</template>

<script>
export default {
	props: {
		item: { type: Object, required: true },
	},
};
</script>
