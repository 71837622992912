<template>
	<div>
		<DaDataInput
			v-model="city"
			name="city"
			:input-name="'city'"
			:da-data-item="DaDataCityItem"
			:initial-value="initialValue"
			:input-options="inputOptions"
			:style-classes="styleClasses"
			:suggest-type="'address'"
			:request-options="suggestionOptions"
		/>
	</div>
</template>

<script>
import DaDataCityItem from '~/components/daData/DadataItems/DaDataCityItem.vue';
import DaDataInput from '@/components/DaDataInput.vue';

export default {
	name: 'DaDataCity',
	components: {
		DaDataInput,
	},
	props: {
		/**
		 * @see https://dadata.ru/api/suggest/
		 */
		value: {
			type: [String, Object],
			required: true,
		},
		styleClasses: {
			type: [Object, String, Number],
			default: '',
		},
		setLabel: {
			type: Function,
			default: (item) => item.value,
		},
		initialValue: {
			type: [String, Object],
			default: null,
		},
		query: {
			type: String,
			default: null,
		},
		/**
		 * @see https://github.com/iamstevendao/vue-suggestion/blob/master/src/components/vue-suggestion.vue
		 */
		inputOptions: {
			type: Object,
			default: () => ({}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		suggestionOptions: {
			type: Object,
			default: () => ({
				from_bound: { value: 'city' },
				to_bound: { value: 'settlement' },
			}),
		},
		minLen: {
			type: Number,
			default: 2,
		},
	},
	data() {
		return {
			DaDataCityItem,
			city: '',
		};
	},
	watch: {
		city: {
			handler(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>
