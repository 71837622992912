<template>
	<div class="loyalty-block">
		<div
			v-if="isLoading"
			class="loyalty-block__charge-points-skeleton"
		/>

		<div
			v-else-if="user?.loyalty_id && !isPointsFetchFailed && (isBlockContainsLoyaltyEarnProduct || isBlockContainsLoyaltyChargeProduct) && (awardedPoints || chargedPoints)"
			class="loyalty-block__card"
		>
			<div
				v-if="loyaltyProfile?.level"
				class="loyalty-block__card-header"
				:style="{
					backgroundColor: cardBackground,
				}"
			>
				<h3 class="loyalty-block__card-title">
					<span class="font-weight-bolder">{{ loyaltyProfile.level.name }}</span> уровень
				</h3>
				<div
					v-if="
						(isBlockContainsLoyaltyEarnProduct || isBlockContainsLoyaltyChargeProduct)
							&& (awardedPoints || chargedPoints)"
					class="loyalty-block__card-subtitle"
				>
					Вы можете
					<span v-if="isBlockContainsLoyaltyEarnProduct">начислить </span>
					<span
						v-if="isBlockContainsLoyaltyEarnProduct && isBlockContainsLoyaltyChargeProduct"
					>или </span>
					<span v-if="isBlockContainsLoyaltyChargeProduct">списать </span>
					баллы
				</div>
			</div>

			<div
				v-if="isBlockContainsLoyaltyEarnProduct || isBlockContainsLoyaltyChargeProduct"
				class="loyalty-block__card-content"
			>
				<div
					v-if="isBlockContainsLoyaltyEarnProduct && awardedPoints"
					class="loyalty-block__awarded-points"
				>
					Начислить <br> {{ $points(awardedPoints) }} баллов
				</div>
				<div
					v-if="isBlockContainsLoyaltyChargeProduct && chargedPoints"
					class="loyalty-block__awarded-points"
				>
					Списать <br> до {{ $points(chargedPoints) }} баллов
				</div>
			</div>
		</div>

		<template v-if="(!user || (user && !user.loyalty_id)) && isBlockContainsLoyaltyEarnProduct">
			<div class="banner-container">
				<img
					src="/loyalty_banner.jpg"
					alt=""
					class="w-100"
				>
				<p class="banner-text font-weight-bold">
					Вам будет начислено 5% от стоимости программы
				</p>
				<a
					class="btn btn-primary banner-btn"
					href="https://www.skolkovo.ru/loyalty-system/"
					target="_blank"
				>
					Подробнее
				</a>
			</div>
		</template>

		<div
			v-if="!isPointsFetchFailed && (isBlockContainsLoyaltyEarnProduct || isBlockContainsLoyaltyChargeProduct) && fullPrice"
			class="loyalty-block__footer"
			:class="{'loyalty-block__footer_row': !isBlockContainsLoyaltyChargeProduct}"
		>
			<div class="loyalty-block__final-price-label">
				Стоимость <span v-if="isBlockContainsLoyaltyChargeProduct && user">
					при списании баллов
				</span>
			</div>
			<div v-if="isBlockContainsLoyaltyChargeProduct && user" class="loyalty-block__final-price">
				{{ $points(finalPriceWithLoyaltyCharge) }} руб
			</div>
			<div v-else class="loyalty-block__final-price">
				{{ $points(fullPrice) }} руб
			</div>
		</div>
	</div>
</template>

<script>
import { LOYALTY_LEVELS } from '~/utils/dictionaries';

export default {
	props: {
		products: {
			type: Array,
			required: true,
		},

		selectedProducts: {
			type: Array,
			required: true,
		},

		isFormulate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			levels: LOYALTY_LEVELS,
			loyaltyProfile: null,
			cardBackground: null,
			isLoading: false,
			isPointsFetchFailed: false,
			awardedPoints: 0,
			chargedPoints: 0,
			finalPriceWithLoyaltyCharge: 0,
			fullPrice: 0,
		};
	},
	computed: {
		user() {
			return this.$auth.user;
		},

		isBlockContainsLoyaltyEarnProduct() {
			return this.selectedProducts.some((product) => !!this.products.find((p) => (p.id === product.id) || (p.id === product))?.is_earn_loyalty)
				&& !this.isPointsFetchFailed;
		},

		isBlockContainsLoyaltyChargeProduct() {
			return this.selectedProducts.some((product) => !!this.products.find((p) => (p.id === product.id) || (p.id === product))?.is_charge_loyalty)
				&& !this.isPointsFetchFailed;
		},
	},

	watch: {
		'$auth.user': {
			async handler(user) {
				if (user) {
					await this.getLoyaltyProfile();

					await this.fetchData();
				}
			},
			immediate: true,
		},

		selectedProducts() {
			this.fetchPoints();
		},
	},

	mounted() {
		this.fetchData();
	},

	methods: {
		async getLoyaltyProfile() {
			try {
				this.isLoading = true;

				const { data } = await this.$api.getLoyaltyProfile();

				if (data) {
					this.loyaltyProfile = data;
					const [currentLevel] = this.levels.filter((el) => el.id === this.loyaltyProfile?.level.id);
					this.cardBackground = currentLevel.color;
				}
			} finally {
				this.isLoading = false;
			}
		},

		async fetchPoints() {
			if (
				!this.isBlockContainsLoyaltyEarnProduct
				&& !this.isBlockContainsLoyaltyChargeProduct
				&& !this.selectedProducts.length
			) {
				this.isPointsFetchFailed = true;

				return;
			}

			if (this.user?.loyalty_id) {
				try {
					this.isLoading = true;

					const products = this.selectedProducts;

					let awardedPoints = 0;

					let chargedPoints = 0;

					let failedCount = 0;

					let fullPrice = 0;

					await Promise.all(products.map(async (product) => {
						const program = this.products.find((p) => (p.id === product) || (p.id === product.id));

						const response = await this.$api.getTransactionInfoByProgram(
							program.id,
							program.price,
							false,
							this.isFormulate,
						).catch(() => {
							failedCount += 1;
						});

						if (response?.points) {
							awardedPoints += parseFloat(response?.points);
						}

						const chargeResponse = await this.$api.getTransactionInfoByProgram(
							program.id,
							program.price,
							true,
							this.isFormulate,
							true,
						).catch(() => {
							failedCount += 1;
						});

						if (chargeResponse?.points) {
							chargedPoints += Math.abs(parseFloat(chargeResponse?.points));
						}

						fullPrice += program.price ? parseFloat(program.price) : 0;
						console.log(program, fullPrice);
					}));

					if (products.length === failedCount / 2) {
						this.isPointsFetchFailed = true;
					}

					this.awardedPoints = Math.floor(awardedPoints);
					this.chargedPoints = Math.floor(chargedPoints);

					this.fullPrice = fullPrice;
					this.finalPriceWithLoyaltyCharge = Math.floor(fullPrice - this.chargedPoints);
				} finally {
					this.isLoading = false;
				}
			}
		},

		async fetchData() {
			this.fullPrice = this.selectedProducts
				.map((product) => {
					const program = this.products
						.find((s) => (s.id === product) || (s.id === product.id));

					return program ? program.price : 0;
				})
				.reduce((total, price) => total + parseFloat(price), 0);

			await this.fetchPoints();
		},
	},
};
</script>
