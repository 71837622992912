<template>
	<div>
		<DaDataInput
			v-model="company"
			name="company"
			:input-name="'company'"
			:set-label="(item) => {
				if (item.value) {
					return `${item.value}`
				}
				return `${item}`;
			}"
			:da-data-item="DaDataCompanyItem"
			:initial-value="value"
			:input-options="inputOptions"
			:style-classes="styleClasses"
			:suggest-type="'party'"
			:is-set-initial-value="true"
			:is-own-type-value="true"
		/>
	</div>
</template>

<script>
import DaDataCompanyItem from '~/components/daData/DadataItems/DaDataCompanyItem.vue';
import DaDataInput from '@/components/DaDataInput.vue';

export default {
	name: 'DaDataCompany',
	components: {
		DaDataInput,
	},
	props: {
		/**
		 * @see https://dadata.ru/api/suggest/
		 */
		value: {
			type: [String, Object],
			required: true,
		},
		styleClasses: {
			type: [Object, String, Number],
			default: '',
		},
		setLabel: {
			type: Function,
			default: (item) => item.value,
		},
		initialValue: {
			type: [String, Object],
			default: null,
		},
		query: {
			type: String,
			default: null,
		},
		/**
		 * @see https://github.com/iamstevendao/vue-suggestion/blob/master/src/components/vue-suggestion.vue
		 */
		inputOptions: {
			type: Object,
			default: () => ({}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		suggestionOptions: {
			type: Object,
			default: () => ({}),
		},
		minLen: {
			type: Number,
			default: 2,
		},
	},
	data() {
		return {
			DaDataCompanyItem,
			company: null,
		};
	},
	watch: {
		company: {
			handler(value) {
				if (value && value.value) {
					this.$emit('input', value);
					this.$emit('setCompanyInfo', value);
				} else {
					this.$emit('input', null);
					this.$emit('setCompanyInfo', null);
				}
			},
		},
	},
};
</script>
