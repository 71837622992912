import isMobile from 'ismobilejs';

export default {
	methods: {
		isMobile() {
			if (process.client) {
				const { userAgent } = window.navigator;
				return isMobile(userAgent).phone;
			}
			return false;
		},
	},
};
